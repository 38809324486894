import React, { Component } from 'react';
import PaginationOne from '../../components/pagination/PaginationOne';
import { createBucketClient } from '@cosmicjs/sdk';

import ScrollAnimation from 'react-animate-on-scroll';
import BannerChicoRegistroBoletos from '../../components/banner/BannerChicoRegistroBoletos';
import EntradaBoleto from '../../components/event/EntradaBoleto';


export default class Boletos extends Component {

    state = {
        boletos: null,

    }


    componentDidMount = async () => {

        const cosmic = createBucketClient({
            bucketSlug: 'caracol-production',
            readKey: 'XGUdTvwEsZcTgQtg65ZQtnGIttwRyLWxyTOJ27fKBCANTqrEps',
          });

          const data = await cosmic.objects
          .find({
            type: 'boletos',
          }).limit(50);

        this.setState({
            boletos: data.objects,
        })
    }

    render() {
        console.log(this.state.boletos);

        if(this.state.boletos){
            this.state.boletos.sort(function(a,b){
                return new Date(b.metadata.fecha) - new Date(a.metadata.fecha);
              });
        }

        {/*
        console.log(this.state.posts);
        console.log(this.state.cats);

        console.log(new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 }).format(Date.now()));
    */}
        return (
            <>
                <BannerChicoRegistroBoletos />


                <div className="edu-elements-area edu-section-gap-m bg-color-white">
                    <div className="container">


                        <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="row g-5">
                                    {
                                        this.state.boletos && this.state.boletos.map((item, index) => (
                                            <ScrollAnimation
                                                animateIn="fadeInUp"
                                                animateOut="fadeInOut"
                                                animateOnce={true}
                                                className="mt--10"
                                                key={index}
                                            >
                                                <EntradaBoleto key={item.id} data={item} />
                                            </ScrollAnimation>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

}