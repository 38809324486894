import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Result = () => {
    return (
        <p className="success-message has-dark-color">Gracias por su mensaje. Estaremos en contacto pronto.</p>
    )
}

function ContactDonacionForm( { formStyle } ) {
    const [result, setResult] = useState( false );


    const form = useRef();
    const sendEmail = ( e ) => {
        e.preventDefault();
        emailjs.sendForm('service_yvbq139', 'template_5yzb88q', form.current, '3R6LBOwt7BXcwfMPH')
        .then((result) => {
            console.log(result.text);
            setResult( true );

        }, (error) => {
            console.log(error.text);
            setResult( false );

        });

        setTimeout(() => {
            setResult( false );
        }, 5000);
    };

    return (
        <form ref={form} className={`${ formStyle }`} action="" onSubmit={ sendEmail }>
            <div className="col-lg-12">
                <div className="form-group">
                    <input type="text" className="form-control form-control-lg" name="user_name" placeholder="Nombre o razón social*" required />
                </div>
            </div>

            <div className="col-lg-12">
                <div className="form-group">
                    <input type="email" className="form-control form-control-lg" name="user_email" placeholder="Correo*" required />
                </div>
            </div>

            <div className="col-lg-12">
                <div className="form-group">
                    <textarea className="form-control" name="message" placeholder="Tu mensaje"></textarea>
                </div>
            </div>
            <div className="col-lg-12">
                <button className="rn-btn edu-btn w-100" type="submit">
                    <span>¡Enviar!</span><i className="icon-arrow-right-line-right"></i></button>
            </div>
            { result ? <div className="form-group"><Result /></div>  : null }
        </form>
    )
}
export default ContactDonacionForm;