import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerConocenos from '../../components/banner/BannerConocenos';
import ContenidoConocenos from '../../components/ContenidoConocenos';
import LineaDeTiempo from '../../components/LineaDeTiempo';
import FooterTwo from '../../common/footer/FooterTwo';
import LinkBannerConocenos from '../../pages/innerpages/LinkBannerConocenos';



const Conocenos = () => {
    return (
        <>
            <SEO title="Caracol Museo de Ciencias" />

            <HeaderTwo styles="header-caracol header-style-2" searchDisable />            
            <BannerConocenos />
            <ContenidoConocenos />
            <LineaDeTiempo />
            <LinkBannerConocenos />            
            <FooterTwo />   
        </>
    )
}

export default Conocenos;