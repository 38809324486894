import React, { Component } from 'react';
import { createBucketClient } from '@cosmicjs/sdk';

import Slider from 'react-slick';
import SEO from '../../common/SEO';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { BlogCarouselParams } from '../../utils/script';
import FooterTwo from '../../common/footer/FooterTwo';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerEducacion from '../../components/banner/BannerEducacion';
import ScrollAnimation from 'react-animate-on-scroll';
import PaginationOne from '../../components/pagination/PaginationOne';
import PostOne from '../../components/post/PostOne';
import BannerRecursos from '../../components/banner/BannerRecursos';
import Recurso from '../../components/post/Recurso';


export default class Recursos extends Component {

    state = {
        posts: null,
        cats: null,
        postsSliced3: null,
        postsSliced12: null,
        filtro: "all"
    }

    async saySomething(something) {
        console.log(something);
    }


    componentDidMount = async () => {

        const cosmic = createBucketClient({
            bucketSlug: 'caracol-production',
            readKey: 'XGUdTvwEsZcTgQtg65ZQtnGIttwRyLWxyTOJ27fKBCANTqrEps',
          });

          const data = await cosmic.objects
          .find({
            type: 'recursos',
          })
          const dataCats = await cosmic.objects
          .find({
            type: 'categoriasrecursos',
          })
     
        this.setState({
            posts: data.objects,
            cats: dataCats.objects,
            postsSliced3: data.objects.slice(0, 3),
            postsSliced12: data.objects,
        })
    }

    handleClick = (slug) => {


        const filterData = this.state.posts.filter(eachVal => {
            let opt = eachVal.metadata.categoria.some((categoria) => categoria.slug.includes(slug));
            return opt;
        })
        if (slug == "all") {
            this.setState({ postsSliced12: this.state.posts, filtro: slug })

        } else {
            this.setState({ postsSliced12: filterData, filtro: slug })

        }

        console.log(slug);
        console.log(this.state.postsSliced12);
    }

    render() {
        console.log(this.state.postsSliced12);

        return (
            <>
                <SEO title="Caracol Museo de Ciencias" />
                <HeaderTwo styles="header-caracol header-style-2" searchDisable />
                <BannerRecursos />

                <div className="edu-elements-area edu-section-gap-m bg-color-white">
                    <div className="container">
                        <div className="row g-5 edu-section-gapBottom-m">
                            <div className="col-lg-12">

                                <div className={`edu-blog-widget widget-categories }`}>
                                    <div className="inner">
                                        <h5 className="widget-title has-dark-color">Categorías</h5>
                                        <div className="content">
                                            <div className={`tag-list  bg-shade' : ''}`}>
                                                <button className={this.state.filtro == "all" ? 'active' : ''} key="unique" onClick={() => this.handleClick('all')}> Todos </button>
                                                {
                                                    this.state.cats && this.state.cats.map(cat => {
                                                        return (
                                                            <button className={this.state.filtro == cat.slug ? 'active' : ''} key={cat.slug} onClick={() => this.handleClick(cat.slug)}> {cat.title} </button>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                         <div className="row g-5">
                            <div className="col-lg-9">
                                <div className="row g-5">
                                    {
                                        this.state.posts && this.state.postsSliced12.map((item, index) => (
                                            <ScrollAnimation
                                                animateIn="fadeInUp"
                                                animateOut="fadeInOut"
                                                animateOnce={true}
                                                className="col-lg-4 col-md-6 col-12"
                                                key={index}
                                            >
                                                <Recurso data={item} />
                                            </ScrollAnimation>
                                        ))
                                    }
                                </div>
                             
                            </div>

                        </div>
                    </div>
                </div>
                <FooterTwo />

            </>
        )
    }

}