import React, { Component } from 'react';
import { createBucketClient } from '@cosmicjs/sdk';


export default class Horario extends Component {

    state = {
        horarios: null,
        horariocierre:null,
        estaabierto:null,
        horariosprint: null,
        test: false
    }

    componentDidMount = async () => {

        const cosmic = createBucketClient({
            bucketSlug: 'caracol-production',
            readKey: 'XGUdTvwEsZcTgQtg65ZQtnGIttwRyLWxyTOJ27fKBCANTqrEps',
          });

          

          var d = new Date().getDay();
          var  titlesDays = ['domingo','lunes', 'martes', 'miercoles', 'jueves','viernes', 'sabado',  ];  
          var name= titlesDays[d];
          console.log("dia:");
          console.log(name);
          
          const dataPrint = await cosmic.objects
          .find({
            type: 'horarios',
          })
          const data = await cosmic.objects
          .findOne({
            slug: `${name}`,
          })


        this.setState({
            horarios: data.object.metadata,
            horario_cierre:data.object.metadata.horario_cierre,
            estaabierto:data.object.metadata.esta_abierto,
            horariosprint: dataPrint.objects,
            test:true,
        })
    }
    render() {
        console.log("horarios........");
        console.log(this.state.horarios);
        console.log(this.state.estaabierto);

        //{this.state.caracteristicas && this.state.caracteristicas.some(item => 'Multimedia' === item) ? 'tooltip-icon' : 'tooltip-icon d-none'}
        
        
        if(this.state.estaabierto){
        return (
            <>
           
          <div style={{ lineHeight : '20px' }}>{'Abierto hasta las ' +this.state.horario_cierre.value}</div> 
            </>
            )
            }else{
                return (
                    <>
                
                <div style={{ lineHeight : '32px' }}>{ 'Cerrado hoy'}</div> 
                    </>
            )
            }
        
    }

}

