import React from 'react';
import CountUp from 'react-countup';
import ScrollAnimation from 'react-animate-on-scroll';
import TrackVisibility from 'react-on-screen';
import SectionTitle from '../../components/sectionTitle/SectionTitle';

const FeatureItems = [
    {
        title: 'Gel Antibacterial',
        image: 'MCA-IconoCovid_GelAntibacterial.png'
    },
    {
        title: 'Mascarilla',
        image: 'MCA-IconoCovid_Mascarilla.png'
    }
];

const FeatureItems2 = [
    {
        title: 'Personal Capacitado',
        image: 'MCA-IconoCovid_PersonalCapacitado.png'
    },
    {
        title: 'Sana DIstancia',
        image: 'MCA-IconoCovid_SanaDistancia.png'
    },
    {
        title: 'Señalética',
        image: 'MCA-IconoCovid_Senaletica.png'
    },
    {
        title: 'Toma Temperatura',
        image: 'MCA-IconoCovid_TomaTemperatura.png'
    }
];



const CovidRules = ( { wrapperClass } ) => {
    return (
        <div className={`eduvibe-home-two-counter bg-color-white ${ wrapperClass || 'edu-section-gap' }`}>
            <div className="container eduvibe-animated-shape">
            <div className="row align-items-center gy-5 edu-section-gapBottom">
                    <div className="col-lg-6">
                        <div className="row g-5 pr--75">
                            { FeatureItems.map( ( data , i ) => (
                                <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    animateOnce={ true }
                                    key={ i }
                                >
                                    <div className="edu-counterup">
                                        <div className="inner">
                                            <div className="icon">
                                                <img src={`${process.env.PUBLIC_URL}./images/IconosCovid/${ data.image }`} alt="Icon Thumb" />
                                            </div>
                                            <div className="content">
           
                                                <p className="has-dark-color">{data.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            ) ) }
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="choose-us-2">
                            <div className="inner">
                                <SectionTitle
                                    classes = "text-left"
                                    title = "Todos nos cuidamos"
                                />
                                <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={ true }
                                >
                                    <p className="description mt--40 mb--30 has-dark-color ">El uso de cubrebocas es obligatorio a partir de los 3 años. Te recomendamos guardar un metro y medio de espacio entre cada visitante. Todos somos responsables de nuestra salud y sano entretenimiento.</p>

                                </ScrollAnimation>

                            
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center gy-5">
                    <div className="col-lg-6">
                        <div className="row g-5 pr--75">
                            { FeatureItems2.map( ( data , i ) => (
                                <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="col-lg-6 col-md-6 col-sm-8 col-12"
                                    animateOnce={ true }
                                    key={ i }
                                >
                                    <div className="edu-counterup">
                                        <div className="inner">
                                            <div className="icon">
                                                <img src={`${process.env.PUBLIC_URL}./images/IconosCovid/${ data.image }`} alt="Icon Thumb" />
                                            </div>
                                            <div className="content">
           
                                            <p className="has-dark-color">{data.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            ) ) }
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="choose-us-2">
                            <div className="inner">
                                <SectionTitle
                                    classes = "text-left"
                                    title = "Nosotros te cuidamos"
                                />
                                <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={ true }
                                >
                                    <p className="description mt--40 mb--30  has-dark-color ">Nuestro personal está capacitado para esta contingencia. Implementamos medidas sanitarias que harán segura tu visita, como lo son gel antibacterial, tapetes desinfectantes y señalética que te recordará los procedimientos de seguridad dentro del museo.</p>

                                </ScrollAnimation>

                            
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-36.png" alt="Shape Thumb" />
                    </div>

                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-29.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="/images/shapes/shape-30.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CovidRules;