import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { createBucketClient } from '@cosmicjs/sdk';

import ScrollAnimation from 'react-animate-on-scroll';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


export default class SalasGalerias extends Component {

    state = {
        sala: null,
        titulo: null,
        duracion: null,
        descripcion: null,
        fechas: null,
        caracteristicas: null,
        imagenes: null,
        sala2: null,
        titulo2: null,
        duracion2: null,
        descripcion2: null,
        fechas2: null,
        caracteristicas2: null,
        imagenes2: null,
        activo2: false,
        dynamicKey: "",
        cartelera: null

    }

    componentDidMount = async () => {

        const cosmic = createBucketClient({
            bucketSlug: 'caracol-production',
            readKey: 'XGUdTvwEsZcTgQtg65ZQtnGIttwRyLWxyTOJ27fKBCANTqrEps',
          });

          const dataCartelera = await cosmic.objects
          .find({
            type: 'cartelera2s',
          })

          const data = await cosmic.objects
          .find({
            type: 'sala-temporal',
          })

          const data2 = await cosmic.objects
          .find({
            type: 'sala-temporal-2',
          })


        this.setState({
            sala: data.objects[0],
            titulo: data.objects[0].title,
            duracion: data.objects[0].metadata.duracion_en_minutos,
            descripcion: data.objects[0].metadata.descripcion,
            fechas: data.objects[0].metadata.fechas,
            caracteristicas: data.objects[0].metadata.caracteristicas,
            imagenes: data.objects[0].metadata.galeria_de_imagenes,
            sala2: data2.objects[0],
            titulo2: data2.objects[0].title,
            duracion2: data2.objects[0].metadata.duracion_en_minutos,
            descripcion2: data2.objects[0].metadata.descripcion,
            fechas2: data2.objects[0].metadata.fechas,
            caracteristicas2: data2.objects[0].metadata.caracteristicas,
            imagenes2: data2.objects[0].metadata.galeria_de_imagenes,
            activo2: data2.objects[0].metadata.activo,
            dynamicKey: "newkey",
            cartelera: dataCartelera.objects,
        })
    }
    render() {
        console.log(this.state.cartelera);

        if (this.state.imagenes) {
            console.log(this.state.imagenes);
            console.log(Object.keys(this.state.imagenes[3]));
            console.log(this.state.imagenes[3][Object.keys(this.state.imagenes[3])[0]]);

        }
        return (
            <div className=" pt-5">
                <div id='section1' className="row align-items-center">
                    <div className="col-xl-6 p-0">
                        <OwlCarousel items={1} autoplayTimeout={5000} autoplayHoverPause className="owl-theme slider-1" autoplay loop >
                            <div className="item">
                                <img src="/images/salacielo/cielo1.jpg" alt="" />
                            </div>
                            <div className="item">
                                <img src="/images/salacielo/cielo2.jpg" alt="" />
                            </div>
                            <div className="item">
                                <img src="/images/salacielo/cielo3.jpg" alt="" />
                            </div>
                            <div className="item">
                                <img src="/images/salacielo/cielo4.jpg" alt="" />
                            </div>
                            <div className="item">
                                <img src="/images/salacielo/cielo5.jpg" alt="" />
                            </div>

                        </OwlCarousel>
                    </div>
                    <div className="col-xl-6">
                        <div className="car-text mt-3 mb-3">
                            <div>
                                <h3 className="title has-cara-blue is-heavy">Sala del Cielo</h3>
                                <p className="description has-dark-color has-medium-rare-font-size">
                                    Astronomía para todos: Movimiento de los planetas alrededor de una estrella, Leyes de Kepler, tipos de telescopios, contaminación lumínica y sus efectos y el giroscopio. Conoce las maravillas que podemos apreciar desde el Observatorio Astronómico Nacional de la UNAM, ubicado en la Sierra de San Pedro Mártir.
                                    <br />

                                </p>
                            </div>
                            <div className="btn-logo-wrap">
                                <div className="s-logo">
                                    <img src="/images/salacielo/logo1.png" alt="" />
                                </div>
                            </div>

                            <div className="icon-wrap mt-3">
                                <div className="tooltip-icon">
                                    <div className="tooltip-text1">
                                        TIEMPO
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/clock.png" alt="" style={{ height: "35px" }} />
                                </div>
                                <div className="tooltip-icon">
                                    <div className="tooltip-text-solo">
                                        30 min
                                    </div>
                                </div>
                            </div>
                            <div className="icon-wrap mt-3">
                                <div className="tooltip-icon">
                                    <div className="tooltip-text1">
                                        ESPACIO
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/1.png" alt="" />
                                    <div className="tooltip-text">
                                        SALA CON MULTIMEDIA
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/2.png" alt="" />
                                    <div className="tooltip-text">
                                        SALA CON EQUIPO DIDÁCTICO
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/3.png" alt="" />
                                    <div className="tooltip-text">
                                        EXPOSICIONES
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/4.png" alt="" />
                                    <div className="tooltip-text">
                                        ESPECIAL PARA NIÑOS
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/5.png" alt="" />
                                    <div className="tooltip-text">
                                        SELFIE SPOT
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='sectionplanetario' className="row align-items-center flex-row-reverse">
                    <div className="col-xl-6 p-0">
                        <OwlCarousel items={1} autoplayTimeout={5000} autoplayHoverPause className="owl-theme slider-1" autoplay loop >
                            <div className="item">
                                <img src="/images/salacielo/Planetario1.jpg" alt="" />

                            </div>
                            <div className="item">
                                <img src="/images/salacielo/Planetario2.jpg" alt="" />

                            </div>
                            <div className="item">
                                <img src="/images/salacielo/Planetario3.jpg" alt="" />

                            </div>
                        </OwlCarousel>
                    </div>
                    <div className="col-xl-6">
                        <div className="car-text">
                            <div>
                                <h3 className="title has-cara-blue is-heavy">Planetario</h3>
                                <p className="description has-dark-color has-medium-rare-font-size mb-0">
                                    Viaja al firmamento y disfruta una experiencia única con nuestras proyecciones astronómicas envolventes. Capacidad para 45 personas.

                                    <br />
                                    <br />
                                </p>
                            </div>


                            <div className="icon-wrap mt-3">
                                <div className="tooltip-icon">
                                    <div className="tooltip-text1">
                                        TIEMPO
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/clock.png" alt="" style={{ height: "35px" }} />
                                </div>
                                <div className="tooltip-icon">
                                    <div className="tooltip-text-solo">
                                        15 min
                                    </div>
                                </div>
                            </div>
                            <div className="icon-wrap mt-3">
                                <div className="tooltip-icon">
                                    <div className="tooltip-text1">
                                        ESPACIO
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/1.png" alt="" />
                                    <div className="tooltip-text">
                                        SALA CON MULTIMEDIA
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/2.png" alt="" />
                                    <div className="tooltip-text">
                                        SALA CON EQUIPO DIDÁCTICO
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/3.png" alt="" />
                                    <div className="tooltip-text">
                                        EXPOSICIONES
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/4.png" alt="" />
                                    <div className="tooltip-text">
                                        ESPECIAL PARA NIÑOS
                                    </div>
                                </div>

                            </div>
                            <br />
                            <div>
                                <h5 className="title has-cara-blue is-heavy">Cartelera</h5>
                                <ul  >
                                    {this.state.cartelera && this.state.cartelera.map(item => (
                                        <li className="lista-cartelera has-dark-color" key={item.title}  >
                                            <i className={item.metadata.enlace == "" ? "" : "fa-solid fa-magnifying-glass"} />
                                            <a style={{ cursor: item.metadata.enlace == "" ? "default" : "pointer" }} target={item.metadata.enlace == "" ? "_self" : "_blank"} href={item.metadata.enlace == "" ? "javascript: void(0)" : item.metadata.enlace} rel="noopener noreferrer">
                                                &nbsp; {item.title}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <div id='section2' className="row align-items-center ">
                    <div className="col-xl-6 p-0">
                        <OwlCarousel items={1} className="owl-theme slider-1" loop >
                            <div className="item">
                                <img src="/images/salacielo/tierra1.jpg" alt="" />
                            </div>
                            <div className="item">
                                <img src="/images/salacielo/tierra2.jpg" alt="" />
                            </div>
                            <div className="item">
                                <img src="/images/salacielo/tierra3.jpg" alt="" />
                            </div>
                            <div className="item">
                                <img src="/images/salacielo/tierra4.jpg" alt="" />
                            </div>
                            <div className="item">
                                <img src="/images/salacielo/tierra5.jpg" alt="" />
                            </div>
                            <div className="item">
                                <img src="/images/salacielo/tierra6.jpg" alt="" />
                            </div>
                        </OwlCarousel>
                    </div>
                    <div className="col-xl-6">
                        <div className="car-text mt-3 mb-3">
                            <div>
                                <h3 className="title has-cara-blue is-heavy">Sala de la Tierra</h3>
                                <p className="description has-dark-color has-medium-rare-font-size">
                                    Disfruta a través de elementos museográficos interactivos, aspectos de nuestro planeta como: origen geológico, islas, flora y fauna, el hombre en la península y sismicidad, entre otros temas. Las exhibiciones cuentan con dispositivos, pantallas e información que sin duda encontrarás entretenidos y educativos.
                                </p>
                            </div>
                            <div className="btn-logo-wrap">
                                <div className="s-logo">
                                    <img src="/images/salacielo/logo2.png" alt="" />
                                </div>

                            </div>
                            <div className="icon-wrap mt-3">
                                <div className="tooltip-icon">
                                    <div className="tooltip-text1">
                                        TIEMPO
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/clock.png" alt="" style={{ height: "35px" }} />
                                </div>
                                <div className="tooltip-icon">
                                    <div className="tooltip-text-solo">
                                        30 min
                                    </div>
                                </div>
                            </div>
                            <div className="icon-wrap mt-3">
                                <div className="tooltip-icon">
                                    <div className="tooltip-text1">
                                        ESPACIO
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/1.png" alt="" />
                                    <div className="tooltip-text">
                                        SALA CON MULTIMEDIA
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/2.png" alt="" />
                                    <div className="tooltip-text">
                                        SALA CON EQUIPO DIDÁCTICO
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/3.png" alt="" />
                                    <div className="tooltip-text">
                                        EXPOSICIONES
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/4.png" alt="" />
                                    <div className="tooltip-text">
                                        ESPECIAL PARA NIÑOS
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/5.png" alt="" />
                                    <div className="tooltip-text">
                                        SELFIE SPOT
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='section3' className="row align-items-center flex-row-reverse">
                    <div className="col-xl-6 p-0">
                        <OwlCarousel items={1} className="owl-theme slider-1" loop >
                            <div className="item">
                                <img src="/images/salacielo/mar1.jpg" alt="" />
                            </div>
                            <div className="item">
                                <img src="/images/salacielo/mar2.jpg" alt="" />
                            </div>
                            <div className="item">
                                <img src="/images/salacielo/mar3.jpg" alt="" />
                            </div>
                            <div className="item">
                                <img src="/images/salacielo/mar4.jpg" alt="" />
                            </div>
                            <div className="item">
                                <img src="/images/salacielo/mar5.jpg" alt="" />
                            </div>
                            <div className="item">
                                <img src="/images/salacielo/mar6.jpg" alt="" />
                            </div>


                        </OwlCarousel>
                    </div>
                    <div className="col-xl-6">
                        <div className="car-text mt-3 mb-3">
                            <div>
                                <h3 className="title has-cara-blue is-heavy">Proyecto Sala del Mar</h3>
                                <p className="description has-dark-color has-medium-rare-font-size">

                                    Conoce nuestros planes para la futura Sala del Mar y Acuario. Esta pequeña sala temporal consta de 3 peceras que representan diferentes ecosistemas de Baja California. Podrás observar caracoles, cangrejos, erizos, estrellas de mar y una diversidad de peces. Además, encontrarás 4 dispositivos interactivos de fauna marina que incluyen uno de realidad virtual.
                                </p>
                            </div>
                            <div className="btn-logo-wrap">
                                <div className="ica-logo">
                                    <img src="/images/salacielo/logo3.png" alt="" />
                                </div>
                            </div>
                            <div className="icon-wrap mt-3">
                                <div className="tooltip-icon">
                                    <div className="tooltip-text1">
                                        TIEMPO
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/clock.png" alt="" style={{ height: "35px" }} />
                                </div>
                                <div className="tooltip-icon">
                                    <div className="tooltip-text-solo">
                                        15 min
                                    </div>
                                </div>
                            </div>
                            <div className="icon-wrap mt-3">
                                <div className="tooltip-icon">
                                    <div className="tooltip-text1">
                                        ESPACIO
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/1.png" alt="" />
                                    <div className="tooltip-text">
                                        SALA CON MULTIMEDIA
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/2.png" alt="" />
                                    <div className="tooltip-text">
                                        SALA CON EQUIPO DIDÁCTICO
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/3.png" alt="" />
                                    <div className="tooltip-text">
                                        EXPOSICIONES
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/4.png" alt="" />
                                    <div className="tooltip-text">
                                        ESPECIAL PARA NIÑOS
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/5.png" alt="" />
                                    <div className="tooltip-text">
                                        SELFIE SPOT
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='section4' className="row align-items-center ">
                    <div className="col-xl-6 p-0">
                        <OwlCarousel items={1} className="owl-theme slider-1" loop key={`carousel_${this.state.dynamicKey}`}>
                            {this.state.imagenes && this.state.imagenes.map(item => (
                                <div className="item" key={item[Object.keys(item)[0]].imgix_url}>
                                    <img src={item[Object.keys(item)[0]].imgix_url} alt="" />

                                </div>
                            ))}

                        </OwlCarousel>
                    </div>
                    <div className="col-xl-6">
                        <div className="car-text mt-3 mb-3">
                            <div>
                                <h3 className="title has-cara-blue has-short-linespacing is-heavy">{this.state.titulo}</h3>
                                <p className="description has-dark-color has-medium-rare-font-size">
                                    {this.state.descripcion}
                                </p>
                            </div>

                            <div className="icon-wrap mt-3">
                                <div className="tooltip-icon">
                                    <div className="tooltip-text1">
                                        {this.state.fechas}
                                    </div>
                                </div>

                            </div>
                            <div className="icon-wrap mt-3">
                                <div className="tooltip-icon">
                                    <div className="tooltip-text1">
                                        TIEMPO
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/clock.png" alt="" style={{ height: "35px" }} />
                                </div>
                                <div className="tooltip-icon">
                                    <div className="tooltip-text-solo">
                                        {this.state.duracion} min
                                    </div>
                                </div>
                            </div>
                            <div className="icon-wrap mt-3">
                                <div className="tooltip-icon ">
                                    <div className="tooltip-text1">
                                        ESPACIO
                                    </div>
                                </div>
                                <div className={this.state.caracteristicas && this.state.caracteristicas.some(item => 'Multimedia' === item) ? 'tooltip-icon' : 'tooltip-icon d-none'}>
                                    <img src="/images/salacielo/1.png" alt="" />
                                    <div className="tooltip-text">
                                        SALA CON MULTIMEDIA
                                    </div>
                                </div>
                                <div className={this.state.caracteristicas && this.state.caracteristicas.some(item => 'Equipo didáctico' === item) ? 'tooltip-icon' : 'tooltip-icon d-none'}>
                                    <img src="/images/salacielo/2.png" alt="" />
                                    <div className="tooltip-text">
                                        SALA CON EQUIPO DIDÁCTICO
                                    </div>
                                </div>
                                <div className={this.state.caracteristicas && this.state.caracteristicas.some(item => 'Exposiciones' === item) ? 'tooltip-icon' : 'tooltip-icon d-none'}>
                                    <img src="/images/salacielo/3.png" alt="" />
                                    <div className="tooltip-text">
                                        EXPOSICIONES
                                    </div>
                                </div>
                                <div className={this.state.caracteristicas && this.state.caracteristicas.some(item => 'Niños' === item) ? 'tooltip-icon' : 'tooltip-icon d-none'}>
                                    <img src="/images/salacielo/4.png" alt="" />
                                    <div className="tooltip-text">
                                        ESPECIAL PARA NIÑOS
                                    </div>
                                </div>
                                <div className={this.state.caracteristicas && this.state.caracteristicas.some(item => 'Selfie spot' === item) ? 'tooltip-icon' : 'tooltip-icon d-none'}>
                                    <img src="/images/salacielo/5.png" alt="" />
                                    <div className="tooltip-text">
                                        SELFIE SPOT
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='section5' className="row align-items-center ">
                
                <div className={this.state.activo2?  "col-xl-6":'hidden' }>
                        <div className="car-text mt-3 mb-3">
                            <div>
                                <h3 className="title has-cara-blue has-short-linespacing is-heavy">{this.state.titulo2}</h3>
                                <p className="description has-dark-color has-medium-rare-font-size">
                                    {this.state.descripcion2}
                                </p>
                            </div>

                            <div className="icon-wrap mt-3">
                                <div className="tooltip-icon">
                                    <div className="tooltip-text1">
                                        {this.state.fechas2}
                                    </div>
                                </div>

                            </div>
                            <div className="icon-wrap mt-3">
                                <div className="tooltip-icon">
                                    <div className="tooltip-text1">
                                        TIEMPO
                                    </div>
                                </div>
                                <div className="tooltip-icon">
                                    <img src="/images/salacielo/clock.png" alt="" style={{ height: "35px" }} />
                                </div>
                                <div className="tooltip-icon">
                                    <div className="tooltip-text-solo">
                                        {this.state.duracion2} min
                                    </div>
                                </div>
                            </div>
                            <div className="icon-wrap mt-3">
                                <div className="tooltip-icon ">
                                    <div className="tooltip-text1">
                                        ESPACIO
                                    </div>
                                </div>
                                <div className={this.state.caracteristicas2 && this.state.caracteristicas2.some(item => 'Multimedia' === item) ? 'tooltip-icon' : 'tooltip-icon d-none'}>
                                    <img src="/images/salacielo/1.png" alt="" />
                                    <div className="tooltip-text">
                                        SALA CON MULTIMEDIA
                                    </div>
                                </div>
                                <div className={this.state.caracteristicas2 && this.state.caracteristicas2.some(item => 'Equipo didáctico' === item) ? 'tooltip-icon' : 'tooltip-icon d-none'}>
                                    <img src="/images/salacielo/2.png" alt="" />
                                    <div className="tooltip-text">
                                        SALA CON EQUIPO DIDÁCTICO
                                    </div>
                                </div>
                                <div className={this.state.caracteristicas2 && this.state.caracteristicas2.some(item => 'Exposiciones' === item) ? 'tooltip-icon' : 'tooltip-icon d-none'}>
                                    <img src="/images/salacielo/3.png" alt="" />
                                    <div className="tooltip-text">
                                        EXPOSICIONES
                                    </div>
                                </div>
                                <div className={this.state.caracteristicas2 && this.state.caracteristicas2.some(item => 'Niños' === item) ? 'tooltip-icon' : 'tooltip-icon d-none'}>
                                    <img src="/images/salacielo/4.png" alt="" />
                                    <div className="tooltip-text">
                                        ESPECIAL PARA NIÑOS
                                    </div>
                                </div>
                                <div className={this.state.caracteristicas2 && this.state.caracteristicas2.some(item => 'Selfie spot' === item) ? 'tooltip-icon' : 'tooltip-icon d-none'}>
                                    <img src="/images/salacielo/5.png" alt="" />
                                    <div className="tooltip-text">
                                        SELFIE SPOT
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.activo2?  "col-xl-6 p-0":'hidden' }>
                        <OwlCarousel items={1} className="owl-theme slider-1" loop key={`carousel_${this.state.dynamicKey}`}>
                            {this.state.imagenes2 && this.state.imagenes2.map(item => (
                                <div className="item" key={item[Object.keys(item)[0]].imgix_url}>
                                    <img src={item[Object.keys(item)[0]].imgix_url} alt="" />

                                </div>
                            ))}

                        </OwlCarousel>
                    </div>
                    
                </div>

            </div>

        )
    }

}

