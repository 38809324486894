import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from '../../common/SEO';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import ContactUsForm from '../../components/contact/ContactUsForm';
import HeaderTwo from '../../common/header/HeaderTwo';
import FooterTwo from '../../common/footer/FooterTwo';
import BannerContactanos from '../../components/banner/BannerContactanos';


const ContactUs = () => {
    return (
        <>
                    <SEO title="Caracol Museo de Ciencias" />

            <HeaderTwo styles="header-caracol header-style-2" searchDisable /> 
            <BannerContactanos/>
                <div className="eduvibe-contact-us edu-contact-us-area edu-section-gap bg-color-white">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <SectionTitle
                                    classes = "text-center"
                                    slogan = "¿Necesitas Ayuda?"
                                    title = "¡Ponte en Contacto!"
                                />
                            </div>
                        </div>
                        <div className="row g-5 mt--20">
                            <div className="col-lg-6">
                                <div className="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
                                    <div className="row g-5">
                                        <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}
                                            className="col-lg-6 col-md-6 col-sm-6 col-12"
                                        >
                                            <div className="contact-address-card-1 website">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <i className="ri-global-line"></i>
                                                    </div>
                                                    <div className="content">
                                                        <h6 className="title color-dark">Nuestro Sitio</h6>
                                                        <p><a href="http://caracolbc.org/">www.caracolbc.org</a></p>
                    
                                                    </div>
                                                </div>
                                            </div>
                                        </ScrollAnimation>
                                        <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}
                                            className="col-lg-6 col-md-6 col-sm-6 col-12"
                                        >
                                            <div className="contact-address-card-1 phone">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <i className="icon-Headphone"></i>
                                                    </div>
                                                    <div className="content">
                                                        <h6 className="title color-dark">Llámanos</h6>
                                                        <p><a href="tel: + 52 (646) 152-1993">+ 52 (646) 152-1993</a></p>
                                                        
                                                    </div>
                                                </div>
                                            </div> 
                                        </ScrollAnimation>
                                        <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}
                                            className="col-lg-6 col-md-6 col-sm-6 col-12"
                                        >
                                            <div className="contact-address-card-1 email">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <i className="icon-mail-open-line"></i>
                                                    </div>
                                                    <div className="content">
                                                        <h6 className="title color-dark">Correo electrónico</h6>
                                                        <p><a href="contacto@caracolbc.org">contacto<br></br>@caracolbc.org</a></p>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </ScrollAnimation>
                                        <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}
                                            className="col-lg-6 col-md-6 col-sm-6 col-12"
                                        >
                                            <div className="contact-address-card-1 location">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <i className="icon-map-pin-line"></i>
                                                    </div>
                                                    <div className="content">
                                                        <h6 className="title color-dark">Nos localizamos</h6>
                                                        <p className='color-dark'>Club Rotario 3, Zona Federal,22800 Ensenada, B.C.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}
                                className="col-lg-6"
                            >
                                <ContactUsForm formStyle="rnt-contact-form rwt-dynamic-form row" />
                            </ScrollAnimation>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-3">
                                <img src="/images/shapes/shape-15.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>

                <FooterTwo />   
            
        </>
    )
}
export default ContactUs;