import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import ScrollAnimation from 'react-animate-on-scroll';


const BannerMar = () => {

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -70; 
        setTimeout(function () {
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
        },2);    }

    return (
        <div className="slider-area banner-style-mar bg-image">
            <div className="wrapper d-flex align-items-center home-four-banner-wrapper">


                <div className="container eduvibe-animated-shape">

                    <div className="row g-5 align-items-center">
                        <div className="col-lg-2 order-2 order-lg-1" />
                        <div className="col-lg-8 order-2 order-lg-1">
                            <div className="inner mt_md--10 mt_sm--10">
                                <div className="content text-center">
                                    <span className="pre-title">Sala del Mar</span>
                                    <h1 className="title">¡Sumérgete y descubre!</h1>
                                    <p className="description">Entra en las profundidades del océano y sorpréndete de las criaturas que habitan este gran mundo marino.</p>
                                    <div className="read-more-btn">
                                    <NavHashLink scroll={el => scrollWithOffset(el)} className="edu-btn" to={process.env.PUBLIC_URL + '/salas#section3'}>VER MÁS<i className="icon-arrow-right-line-right"></i></NavHashLink>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 order-2 order-lg-1" />
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image-sala shape-image-Ballena">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                             
                            delay={300}
                        >
                            <img src="/images/imgsHome/Ballena.webp" alt="Shape Thumb" />
                        </ScrollAnimation>
                        <div className="shape-nombre">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                 
                                delay={600}
                            >
                                <img src="/images/imgsHome/Ballena-nombre.webp" alt="Shape Thumb" />
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="shape-image-sala shape-image-Vaquita">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                             
                            delay={300}
                        >
                            <img src="/images/imgsHome/Vaquita.webp" alt="Shape Thumb" />
                        </ScrollAnimation>
                        <div className="shape-nombre">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                 
                                delay={600}
                            >
                                <img src="/images/imgsHome/Vaquita-nombre.webp" alt="Shape Thumb" />
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="shape-image-sala shape-image-Manta">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                             
                            delay={300}
                        >
                            <img src="/images/imgsHome/Manta.webp" alt="Shape Thumb" />
                        </ScrollAnimation>
                        <div className="shape-nombre">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                 
                                duration={.5} 
                            >
                                <img src="/images/imgsHome/Manta-nombre.webp" alt="Shape Thumb" />
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="shape-image-sala shape-image-Tortuga">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                             
                            delay={300}
                        >
                            <img src="/images/imgsHome/Tortuga.webp" alt="Shape Thumb" />
                        </ScrollAnimation>
                        <div className="shape-nombre">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                 
                                delay={600}
                            >
                                <img src="/images/imgsHome/Tortuga-nombre.webp" alt="Shape Thumb" />
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="shape-image-sala shape-image-Totoaba">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                             
                            delay={300}
                        >
                            <img src="/images/imgsHome/Totoaba.webp" alt="Shape Thumb" />
                        </ScrollAnimation>
                        <div className="shape-nombre">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                 
                                delay={600}
                            >
                                <img src="/images/imgsHome/Totoaba-nombre.webp" alt="Shape Thumb" />
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="shape-image-sala shape-image-Delfin">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                             
                            delay={300}
                        >
                            <img src="/images/imgsHome/Delfin.webp" alt="Shape Thumb" />
                        </ScrollAnimation>
                        <div className="shape-nombre">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                 
                                delay={600}
                            >
                                <img src="/images/imgsHome/Delfin-nombre.webp" alt="Shape Thumb" />
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerMar;