import React, { Component } from 'react';
import CountUp from 'react-countup';
import ScrollAnimation from 'react-animate-on-scroll';
import TrackVisibility from 'react-on-screen';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import GoogleMap from '../../components/contact/GoogleMap';

import { PayPalButton } from "react-paypal-button-v2";
import { Link } from 'react-router-dom';



export default class ListaDescargables extends Component {




	render() {
		return (
			<>

				<div id="section1" className={`eduvibe-home-two-counter bg-color-white `}>
					<section id="tickets" className="section-padd-100">
						<div className="container">
							<div className="section-title">
								<h2>Consulta nuestros Informes de actividades</h2>
							</div>
							<div className="tickets">
							<div className="tickets-box">
									<div className="tickets-left">
										<Link to={process.env.PUBLIC_URL + '/Informedeactividades2023.pdf'} target="_blank" download><p>Descargar</p></Link>
									</div>
									<div className="tickets-center">
										<p>INFORME 2023</p>
									</div>
									<div className="tickets-right">

									</div>
								</div>
								<div className="tickets-box">
									<div className="tickets-left">
										<Link to={process.env.PUBLIC_URL + '/Informedeactividades2022.pdf'} target="_blank" download><p>Descargar</p></Link>
									</div>
									<div className="tickets-center">
										<p>INFORME 2022</p>
									</div>
									<div className="tickets-right">

									</div>
								</div>
								<div className="tickets-box">
									<div className="tickets-left">
										<Link to={process.env.PUBLIC_URL + '/Informedeactividades2021.pdf'} target="_blank" download><p>Descargar</p></Link>
									</div>
									<div className="tickets-center">
										<p>INFORME 2021</p>
									</div>
									<div className="tickets-right">

									</div>
								</div>
								<div className="tickets-box">
									<div className="tickets-left">
										<Link to={process.env.PUBLIC_URL + '/Informedeactividades2020.pdf'} target="_blank" download><p>Descargar</p></Link>
									</div>
									<div className="tickets-center">
										<p>INFORME 2020</p>
									</div>
									<div className="tickets-right">

									</div>
								</div>
								<div className="tickets-box">
									<div className="tickets-left">
										<Link to={process.env.PUBLIC_URL + '/Informedeactividades2019.pdf'} target="_blank" download><p>Descargar</p></Link>
									</div>
									<div className="tickets-center">
										<p>INFORME 2019</p>
									</div>
									<div className="tickets-right">

									</div>
								</div>
								<div className="tickets-box">
									<div className="tickets-left">
										<Link to={process.env.PUBLIC_URL + '/Informedeactividades2018.pdf'} target="_blank" download><p>Descargar</p></Link>
									</div>
									<div className="tickets-center">
										<p>INFORME 2018</p>
									</div>
									<div className="tickets-right">

									</div>
								</div>
								<div className="tickets-box">
									<div className="tickets-left">
										<Link to={process.env.PUBLIC_URL + '/Informedeactividades2017.pdf'} target="_blank" download><p>Descargar</p></Link>
									</div>
									<div className="tickets-center">
										<p>INFORME 2017</p>
									</div>
									<div className="tickets-right">

									</div>
								</div>
								<div className="tickets-box">
									<div className="tickets-left">
										<Link to={process.env.PUBLIC_URL + '/Informedeactividades2016.pdf'} target="_blank" download><p>Descargar</p></Link>
									</div>
									<div className="tickets-center">
										<p>INFORME 2016</p>
									</div>
									<div className="tickets-right">

									</div>
								</div>


							</div>

						</div>
					</section>


				</div>

			</>
		)

	}
}
