import React from 'react'
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll'

const BannerChicoColabora = () => {
  return (
    <div className="slider-area banner-style-chico-colabora bg-image">
      <div className="wrapper d-flex align-items-center home-four-banner-wrapper">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-lg-2 order-2 order-lg-1" />
            <div className="col-lg-8 order-2 order-lg-1">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h3 style={{ textAlign: 'right' }}>Dejando huella</h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description" style={{ textAlign: 'right' }}>
                  Es una campaña que te da la oportunidad de aportar para el
                  desarrollo de Caracol, y al mismo tiempo, dejar un mensaje
                  grabado que será leído por cada visitante del museo.
                </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <img
                  src={`${process.env.PUBLIC_URL}./images/colabora/bullet-banner.png`}
                  alt="Icon Thumb"
                  style={{ float: 'right' }}
                />
              </ScrollAnimation>
            </div>
            <div className="col-lg-2 order-2 order-lg-1" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerChicoColabora
