import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import SalasGalerias from '../../components/SalasGalerias';
import GaleriaEventos from '../../components/GaleriaEventos';
import FooterTwo from '../../common/footer/FooterTwo';


const Salas = () => {
    return (
        <>
            <SEO title="Caracol Museo de Ciencias" />

            <HeaderTwo styles="header-caracol header-style-2" searchDisable />            
            <SalasGalerias />
            <GaleriaEventos />
            <FooterTwo />   
        </>
    )
}

export default Salas;