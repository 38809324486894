import React, { useState, useContext } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import SectionTitle from './sectionTitle/SectionTitle';

const pricingPlan = {
    rightColumn: [
        {
            id: 1,
            title: 'CEMEFI',
            body: ''

        },
        {
            id: 2,
            title: 'CONFÍO',
            body: 'contenido 2'
        },
        {
            id: 3,
            title: 'SAT',
            body: 'contenido 3'
        }
    ]
}

const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey);
    const isCurrentEventKey = activeEventKey === eventKey;
    return <button type="button" onClick={decoratedOnClick} aria-expanded={isCurrentEventKey ? true : false} className="edu-accordion-button">{children}</button>
}

const ContenidoPoliticaDePrivacidad = ({ wrapperclassName }) => {

    const [selectedItem, setSelectedItem] = useState('online-education');
    const [activeId1, setActiveId1] = useState('0');
    const [activeId2, setActiveId2] = useState('0');
    const [activeId3, setActiveId3] = useState('0');
    const [activeId4, setActiveId4] = useState('0');
    const [activeId5, setActiveId5] = useState('0');
    const [activeId6, setActiveId6] = useState('0');

    function toggleActive1(id) {
        if (activeId1 === id) {
            setActiveId1(null);
        } else {
            setActiveId1(id);
        }
    }
    function toggleActive2(id) {
        if (activeId2 === id) {
            setActiveId2(null);
        } else {
            setActiveId2(id);
        }
    }
    function toggleActive3(id) {
        if (activeId3 === id) {
            setActiveId3(null);
        } else {
            setActiveId3(id);
        }
    }
    function toggleActive4(id) {
        if (activeId4 === id) {
            setActiveId4(null);
        } else {
            setActiveId4(id);
        }
    }
    function toggleActive5(id) {
        if (activeId5 === id) {
            setActiveId5(null);
        } else {
            setActiveId5(id);
        }
    }
    function toggleActive6(id) {
        if (activeId6 === id) {
            setActiveId6(null);
        } else {
            setActiveId6(id);
        }
    }

    return (

        <div className="container-conocenos">
            <div className="row">

                <div className="col-lg-12">
                    <div className={`edu-accordion-area accordion-shape-1 edu-section-gap bg-color-white ${wrapperclassName ? wrapperclassName : ''} `}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <p className='has-dark-color'>

                                        Caracol, Centro Científico y Cultural, A.C., mejor conocido como Caracol Museo de Ciencias, con domicilio en calle
                                        Club Rotario 3, colonia Zona Centro, ciudad Ensenada, municipio o delegación Ensenada, c.p. 22800, en la entidad
                                        de Baja California, país México, y portal de internet www.caracolbc.org, es el responsable del uso y protección de sus
                                        datos personales, y al respecto le informamos lo siguiente:
                                        <br/> <br/> 
                                        <strong>¿Para qué fines utilizaremos sus datos personales?</strong>
                                        <br/> <br/> 
                                        Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias
                                        para el servicio que solicita:
                                        <br/> <br/> 
                                        ● Datos para compras en linea
                                        <br/> 
                                        ● Donativos
                                        <br/> 
                                        ● Prospección comercial
                                        <br/> <br/> 
                                        De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que <strong>no son
                                        necesarias</strong> para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:
                                        <br/> <br/> 
                                        ● Boletines informativos
                                        <br/> 
                                        ● Registro en eventos
                                        <br/> 
                                        ● Mercadotecnia o publicitaria
                                        <br/> <br/> 
                                        En caso de que no desee que sus datos personales se utilicen para estos fines secundarios, indíquelo a continuación:
                                        <br/> <br/> 
                                        No consiento que mis datos personales se utilicen para los siguientes fines:
                                        <br/> <br/> 
                                        [ ] Boletines informativos
                                        <br/> 
                                        [ ] Registro en eventos
                                        <br/> 
                                        [ ] Mercadotecnia o publicitaria
                                        <br/> <br/> 

                                        La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos
                                        los servicios y productos que solicita o contrata con nosotros.
                                        <br/> <br/> 
                                        <strong>¿Qué datos personales utilizaremos para estos fines?</strong>
                                        <br/> <br/> 
                                        Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos
                                        personales:
                                        <br/> <br/> 
                                        ● Datos de identificación
                                        <br/> 
                                        ● Datos de contacto
                                        <br/> <br/> 
                                        <strong>¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</strong>
                                        <br/> <br/> 
                                        Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del
                                        uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de
                                        que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases
                                        de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse
                                        al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos
                                        ARCO.
                                        <br/> <br/> 
                                        Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del
                                        siguiente medio:
                                        <br/> <br/> 
                                        Enviando un correo electrónico a:  <a className='link-conocenos' href="comunicacion@caracolbc.org"> comunicacion@caracolbc.org</a>
                                        <br/> <br/> 
                                        Para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, ponemos a su disposición el
                                        siguiente medio:
                                        <br/> <br/> 
                                        <a className='link-conocenos' href="comunicacion@caracolbc.org">Correo electrónico</a>

                                        <br/> <br/> 
                                        Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las
                                        solicitudes de derechos ARCO, son los siguientes:
                                        <br/> <br/> 
                                        a) Nombre de la persona o departamento de datos personales: Coordinación de Comunicación
                                        <br/>
                                        b) Domicilio: calle Club Rotario 3, Zona Federal, colonia Centro, ciudad Ensenada, municipio o delegación Ensenada,
                                        c.p. 22800, en la entidad de Baja California, país México
                                        <br/>
                                        c) Correo electrónico:                                        <a className='link-conocenos' href="comunicacion@caracolbc.org"> comunicacion@caracolbc.org</a>

                                        <br/><br/>
                                        d) Número telefónico: 6461521993
                                        <br/><br/>
                                        Otro dato de contacto: 6461770897
                                        <br/><br/>
                                        <strong>Usted puede revocar su consentimiento para el uso de sus datos personales</strong>
                                        <br/><br/>
                                        Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos
                                        personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su
                                        solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir
                                        tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su
                                        consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su
                                        relación con nosotros.
                                        <br/>
                                        Para revocar su consentimiento deberá presentar su solicitud a través del siguiente medio:
                                        <br/><br/>
                                        <a className='link-conocenos' href="comunicacion@caracolbc.org">correo electrónico</a>
                                        <br/><br/>
                                        Para conocer el procedimiento y requisitos para la revocación del consentimiento, ponemos a su disposición el
                                        siguiente medio:
                                        <br/><br/>
                                        <a className='link-conocenos' href="comunicacion@caracolbc.org">correo electrónico</a>
                                        <br/><br/>
                                        <strong>¿Cómo puede limitar el uso o divulgación de su información personal?</strong>
                                        <br/><br/>
                                        Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes
                                        medios:
                                        <br/>
                                        Eliminación de sus datos de la base de datos general
                                        <br/>
                                        Asimismo, usted se podrá inscribir a los siguientes registros, en caso de que no desee obtener publicidad de nuestra
                                        parte:
                                        <br/>
                                        Registro Público de Usuarios, para mayor información consulte el portal de internet de la CONDUSEF
                                        <br/><br/>
                                        <strong>¿Cómo puede conocer los cambios en este aviso de privacidad?</strong>
                                        <br/><br/>
                                        El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos
                                        requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras
                                        prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.
                                        Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a
                                        través de: <a className='link-conocenos' href="comunicacion@caracolbc.org">Correo Electrónico</a>.
                                        <br/><br/>
                                        El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente
                                        aviso de privacidad es el siguiente:
                                        <br/><br/>
                                        Envió a través de                                         <a className='link-conocenos' href="comunicacion@caracolbc.org">correo electrónico</a>

                                        <br/><br/>
                                        Última actualización: 23/06/2021
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid px-lg-5 py-5 text-section">
                <div className="row">
                    <div className="col-lg-6">

                    </div>
                    <div className="col-lg-6">


                    </div>
                </div>
            </div>

        </div>




    )
}

export default ContenidoPoliticaDePrivacidad;


