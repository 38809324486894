import React, { Component } from 'react';
import { useParams } from "react-router-dom";
import { createBucketClient } from '@cosmicjs/sdk';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils'; import SEO from '../../common/SEO';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { BlogCarouselParams } from '../../utils/script';
import FooterTwo from '../../common/footer/FooterTwo';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerEducacion from '../../components/banner/BannerEducacion';
import ScrollAnimation from 'react-animate-on-scroll';
import PaginationOne from '../../components/pagination/PaginationOne';
import PostOne from '../../components/post/PostOne';
import CategoryOne from '../../components/widgets/blog/CategoryOne';
import TagOne from '../../components/widgets/blog/TagOne';
import PostData from '../../data/blog/PostData.json';
import BannerChicoEducacuion from '../../components/banner/BannerChicoEducacuion';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class BlogDetailsOne extends Component {
    state = {
        post: null,
        autor: null,
        cats:null
    }

    componentDidMount = async () => {

        let { id } = this.props.params;
        console.log("id:" + id)
        const cosmic = createBucketClient({
            bucketSlug: 'caracol-production',
            readKey: 'XGUdTvwEsZcTgQtg65ZQtnGIttwRyLWxyTOJ27fKBCANTqrEps',
          });

          const data = await cosmic.objects
          .findOne({
            slug: `${id}`,
          })
          console.log(data);

          const cats = await cosmic.objects
          .find({
            type: 'categories',
          })
          console.log(cats.objects);
          
          const autor = await cosmic.objects
          .findOne({
            id: `${data.object.metadata.author}`,
          })

          console.log(autor);


        this.setState({
            post: data.object,
            autor:autor.object,
            cats:cats.objects
        })
    }



    render() {


        if (this.state.post != undefined) {

            var fecha = "";
            var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

            console.log(this.state.post);
            console.log(this.state.post.created);
            var date = new Date(this.state.post.created);
            fecha = date.getDay() + " de " + meses[date.getMonth()] + ", " + date.getFullYear();

            var categories=[];
            this.state.post.metadata.categories.map((tag, i) => {
                var result = this.state.cats.find(obj => {
                    return obj.id === tag
                  })
                  categories.push(result);
            })


            return (
                <>
                    <SEO title="Caracol Museo de Ciencias" />
                    <HeaderTwo styles="header-caracol header-style-2" searchDisable />

                    <div className="edu-blog-details-area edu-section-gap bg-color-white">
                        <div className="container">
                            <div className="row g-5">
                                <div className="col-lg-10 offset-lg-1">
                                    <div className="blog-details-1 style-variation3">

                                        <div className="content-blog-top">

                                            <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                                                <div className="status-group">
                                                    <a className="text-uppercase eduvibe-status status-05 color-primary w-600" >
                                                    <i className="icon-price-tag-3-line"></i> {this.state.autor.title}
                                                    </a>
                                                </div>
                                                <ul className="blog-meta">
                                                    <li className="blog-author has-dark-color"><img src={this.state.autor.metadata.image.imgix_url} alt="Blog Images" /> <a >{this.state.autor.title}</a></li>
                                                    <li className="has-dark-color"><i className="icon-calendar-2-line has-dark-color"></i>{fecha}</li>
                                                </ul>
                                            </div>

                                            <h4 className="title has-dark-color">{this.state.post.title}</h4>

                                            <div className="thumbnail block-alignwide">
                                                <img className="radius-small w-100 mb--30" src={this.state.post.metadata.portada.imgix_url} alt="Blog Thumb" />
                                            </div>
                                        </div>

                                        <div className="blog-main-content">
                                            <p className="has-dark-color" dangerouslySetInnerHTML={{ __html: this.state.post.metadata.texto }} ></p>

                                        </div>

                                       <div className="blog-tag-and-share mt--50">
                                            {categories && categories.length > 0 &&
                                                <div className="blog-tag">
                                                    <div className="tag-list bg-shade">
                                                        {
                                                            categories.map((tag, i) => {
                                                                return (
                                                                    <button className={this.state.filtro==tag.slug ? 'active bg-shade' : 'bg-shade' } key={tag.slug} > {tag.title} </button>

 
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            }
                                             {/* <div className="eduvibe-post-share">
                                            <span className="has-dark-color">Share: </span>
                                            <a className="linkedin" href="#"><i className="icon-linkedin has-dark-color"></i></a>
                                            <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                            <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                            <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                    </div>*/}
                                        </div>

                                        <div className="blog-author-wrapper">
                                            <div className="thumbnail">
                                                <img src={this.state.autor.metadata.image.url} alt="Author Images" />
                                            </div>
                                            <div className="author-content">
                                                <h6 className="title has-dark-color">{this.state.autor.title}</h6>
                                                <p className="has-dark-color" dangerouslySetInnerHTML={{ __html: this.state.autor.content }} ></p>

                                                {/*<ul className="social-share icon-transparent">
                                                <li><a href="#"><i className="icon-Fb"></i></a></li>
                                                <li><a href="#"><i className="icon-linkedin"></i></a></li>
                                                <li><a href="#"><i className="icon-Pinterest"></i></a></li>
                                                <li><a href="#"><i className="icon-Twitter"></i></a></li>
                                </ul>*/}
                                            </div>
                                        </div>

                                        {/* <div className="blog-pagination">
                                        <div className="row g-5">
                                            <div className="col-lg-6">
                                                <div className="blog-pagination-list style-variation-2">
                                                    <a href="#">
                                                        <i className="ri-arrow-left-s-line"></i>
                                                        <span className="has-dark-color">Nam libero justo laoreet sit amet. Lacus sed viverra tellus in hac</span>
                                                    </a>
                                                </div>
                                            </div>
    
                                            <div className="col-lg-6">
                                                <div className="blog-pagination-list style-variation-2 next-post">
                                                    <a href="#">
                                                        <span className="has-dark-color">Tempus imperdiet nulla malesuada pellentesque elit eget gravida</span>
                                                        <i className="ri-arrow-right-s-line"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterTwo />

                </>
            )
        }
        return (
            <>
                <h1>LOADING</h1>
            </>
        )


    }

} export default withParams(BlogDetailsOne);