import React from 'react';
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';


const HomeOneAbout = () => {
    return (
        <Element
            name="about-us"
            className="edu-about-area about-style-1 edu-section-gap bg-color-white"
        >
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-6">
                        <div className="about-image-gallery">
                            <img className="image-1" src="/images/about/about-09/about-image-01.jpg" alt="About Main Thumb" />
                           
                            <div className="badge-inner">
                                <img className="image-3" src="/images/about/about-09/badge.png" alt="About Circle Thumb" />
                            </div>
                           
                        </div>
                    </div>
                   
                </div>
            </div>
        </Element>
    )
}

export default HomeOneAbout;