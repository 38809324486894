import React, { useState, useContext } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import SectionTitle from './sectionTitle/SectionTitle';

const pricingPlan = {
    rightColumn: [
        {
            id: 1,
            title: 'CEMEFI',
            body: ''

        },
        {
            id: 2,
            title: 'CONFÍO',
            body: 'contenido 2'
        },
        {
            id: 3,
            title: 'SAT',
            body: 'contenido 3'
        }
    ]
}

const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey);
    const isCurrentEventKey = activeEventKey === eventKey;
    return <button type="button" onClick={decoratedOnClick} aria-expanded={isCurrentEventKey ? true : false} className="edu-accordion-button">{children}</button>
}

const ContenidoTransparencia = ({ wrapperclassName }) => {

    const [activeId6, setActiveId6] = useState('0');


    function toggleActive6(id) {
        if (activeId6 === id) {
            setActiveId6(null);
        } else {
            setActiveId6(id);
        }
    }

    return (

        <div className="container-conocenos">
            <div className="row">

                <div className="col-lg-12">
                    <div className={`edu-accordion-area accordion-shape-1 bg-color-white ${wrapperclassName ? wrapperclassName : ''} `}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        classNamees="text-center"
                                        title=""
                                    />
                                </div>
                            </div>
                            <div className="row g-5 align-items-center">
                                <div className="col-lg-12">
                                    <ScrollAnimation
                                        animateIn="fadeIn"
                                        animateOut="fadeInOut"
                                        className="accordion-style-1"
                                        animateOnce={true}
                                    >
                                        <div className="row g-5 mt--10">
                                            <div className="col-xl-10 offset-xl-1">
                                                <div className="animated fadeInUp" >
                                                    <div className=" edu-accordion-02 variation-2 ">

                                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                                            <Accordion.Item eventKey="0" key="0" onClick={() => toggleActive6("0")} bsPrefix={`edu-accordion-item ${activeId6 === "0" ? 'active-item' : ''} `}>
                                                                <div className="edu-accordion-header">
                                                                    <CustomToggle eventKey="0">CEMEFI</CustomToggle>
                                                                </div>
                                                                <Accordion.Body bsPrefix="edu-accordion-body has-dark-color">
                                                                    <div className="row">
                                                                        <div className="col-lg-2">
                                                                            <div className="container">

                                                                                <img className="one" src={`${process.env.PUBLIC_URL}./images/Transparencia/cemefi.png`} alt="Footer Logo" />

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-10">
                                                                            <div className="container">
                                                                                <p className="has-dark-color">
                                                                                    El Centro Mexicano para la Filantropía <a className='link-conocenos' href="https://www.cemefi.org/cemefi/informacion-institucional" target="_blank">(Cemefi)</a>, es una asociación civil mexicana, privada, no lucrativa, sin ninguna filiación a partido, raza o religión. Está legalmente autorizada para recibir donativos deducibles de impuestos. Su misión es "Promover y articular la participación filantrópica, comprometida y socialmente responsable de los ciudadanos y sus organizaciones, para alcanzar una sociedad más equitativa, solidaria y próspera."
                                                                                    <br></br> <br></br>

                                                                                    Cemefi otorga una Acreditación en Institucionalidad y Transparencia <a className='link-conocenos' href="https://ait.cemefi.org/Paginas/%C2%BFQu%C3%A9-es-la-AIT.aspx" target="_blank">(AIT)</a>, que refleja el nivel de desarrollo institucional de una organización privada, no lucrativa y de servicios a terceros. Cualquier persona, empresa o institución puede conocer con certeza si esa organización:
                                                                                    <br></br><br></br>

                                                                                    · Existe y opera realmente.<br></br>
                                                                                    · Cumple con los requisitos legales mínimos.<br></br>
                                                                                    · Dispone de misión, visión y objetivos que orienten sus servicios a las personas que atiende.<br></br>
                                                                                    · Cuenta con mecanismos institucionales de transparencia y rendición de cuentas.<br></br>
                                                                                    · Tiene un gobierno corporativo y una planeación estratégica para garantizar su desarrollo económico, social e institucional.<br></br><br></br>

                                                                                    El pasado 17 de octubre del 2023, volvimos a recibir la “Acreditación en Institucionalidad y Transparencia (AIT) 2023”, contando con el nivel óptimo, que estará vigente hasta el 31 de julio del 2025.<br></br><br></br>

                                                                                    Nivel Básico — Si cubre con los cinco primeros indicadores.<br></br>
                                                                                    Nivel Medio — Si cubre con los cinco indicadores básicos, más otros dos o tres indicadores.<br></br>
                                                                                    Nivel Óptimo — Si cubre con los cinco indicadores básicos, más cuatro o cinco indicadores adicionales.<br></br>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div></Accordion.Body>
                                                            </Accordion.Item>

                                                        </Accordion>

                                                     

                                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                                            <Accordion.Item eventKey="2" key="2" onClick={() => toggleActive6("2")} bsPrefix={`edu-accordion-item ${activeId6 === "2" ? 'active-item' : ''}  `}>
                                                                <div className="edu-accordion-header">
                                                                    <CustomToggle eventKey="2">SAT</CustomToggle>
                                                                </div>
                                                                <Accordion.Body bsPrefix="edu-accordion-body has-dark-color"><div className="row">
                                                                    <div className="col-lg-2">
                                                                        <div className="container">
                                                                            <img className="one" src={`${process.env.PUBLIC_URL}/images/Transparencia/sat.png`} alt="Footer Logo" />

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-10">
                                                                        <div className="container">
                                                                            <p className="has-dark-color">
                                                                                El 21 de febrero del 2019, el SAT nos informa, mediante expediente No: 5003, No. Folio: 1887320, que se continúa con la autorización para recibir donativos deducibles del Impuesto sobre la Renta en el rubro K (Organizaciones civiles y fideicomisos propietarios de museos privados con acceso al público en general, artículo 134 del Reglamento de la Ley del ISR). Esta información es obligatoria en transparencia (fundamento Artículo 29-A, fracción V, inciso b), CFF).
                                                                                <br></br>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div></Accordion.Body>
                                                            </Accordion.Item>

                                                        </Accordion>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </ScrollAnimation>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <br />
            <br />

        </div>



    )
}

export default ContenidoTransparencia;


