import React, { useState } from 'react'
import CountUp from 'react-countup'
import ScrollAnimation from 'react-animate-on-scroll'
import TrackVisibility from 'react-on-screen'
import SectionTitle from '../../components/sectionTitle/SectionTitle'
import ContactDonacionForm from '../../components/contact/ContactDonacionForm'
import { NavHashLink } from 'react-router-hash-link'
import BannerChicoColabora from '../../components/banner/BannerChicoColabora'
import SwitchSelector from 'react-switch-selector'

const ContenidoColabora = ({ wrapperClass }) => {
  const options = [
    {
      label: <span>Placas Individuales</span>,
      value: false,
      selectedBackgroundColor: '#3bb2de',
    },
    {
      label: 'Placas Empresariales',
      value: true,
      selectedBackgroundColor: '#3bb2de',
    },
  ]

  const onChange = (newValue) => {
    console.log(newValue)
    setPlacasExtra(newValue);
  }

  const [placasExtra, setPlacasExtra] = useState(false)

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
    const yOffset = -70
    setTimeout(function () {
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
    }, 2)
  }

  const AbrirFormularioAmigos = () => {
    window.open('https://forms.gle/UCiVmcXcJRGoQqg89')
  }

  const AbrirFormularioDatos = () => {
    window.open('https://forms.gle/CtfYCLo9MHawASX19')
    console.log('Click!!!!')
  }

  const AbrirFormularioDonacionesTierra = () => {
    window.open('https://forms.gle/mthGSxDTBNhXHo959')
  }
  const AbrirFormularioDonacionesCielo = () => {
    window.open('https://forms.gle/4VydDNfdNEKomksB7')
  }
  const AbrirFormularioDonacionesMar = () => {
    window.open('https://forms.gle/FxyBfNvjFvrv4L4i8')
  }
  return (
    <div>
      {/*////////////////////////////  MEMBRESIAS  //////////////////////////////////////////*/}

      <div id="section1" className="container eduvibe-animated-shape edu-section-gap">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="row g-5 pr--50 pl--50">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <img
                  src={`${process.env.PUBLIC_URL}./images/colabora/personas.png`}
                  alt="Icon Thumb"
                />
              </ScrollAnimation>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row g-5 pr--50 pl--50">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <img
                  src={`${process.env.PUBLIC_URL}./images/colabora/texto1.png`}
                  alt="Icon Thumb"
                />
              </ScrollAnimation>
            </div>
            <div className="row g-5 pr--50 pl--50">
              <div className="choose-us-2">
                <div className="inner">
                  <SectionTitle classes="text-left" title="Membresías" />
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}
                  >
                    <p className="description mt--40 mb--30 has-dark-color ">
                      Al ser un miembro de Caracol, estás contribuyendo al
                      mantenimiento y desarrollo de programas educativos y
                      exhibiciones atractivas que inspiran el aprendizaje de las
                      ciencias en nuestra comunidad.
                    </p>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*////////////////////////////  BARRA  //////////////////////////////////////////*/}

      <div
        className="container eduvibe-animated-shape edu-section-gapBottom"
        style={{ maxWidth: '970px' }}
      >
        <div className="row align-items-center gy-5 ">
          <div className="col-lg-12">
            <div className="row g-5 pr--50 pl--50">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <img
                  src={`${process.env.PUBLIC_URL}./images/colabora/barra.png`}
                  alt="Icon Thumb"
                  className="mx-auto d-block"
                />
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>

      {/*////////////////////////////  TABLA BENEFICIOS  //////////////////////////////////////////*/}

      <div
        className="container eduvibe-animated-shape"
        style={{ maxWidth: '970px' }}
      >
        <div className="row ">
          <div className="col-12">
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <img
                src={`${process.env.PUBLIC_URL}./images/colabora/tablaBeneficiosColabora.webp`}
                alt="Icon Thumb"
                className="mx-auto d-block"
              />
            </ScrollAnimation>
          </div>
          <div className="row  edu-section-gapBottom">
            <div className="col-4"></div>
            <div className="col-2">
              <div className="read-more-btn d-flex align-items-center justify-content-center">
                <a
                  className="edu-btn"
                  onClick={AbrirFormularioAmigos}
                  style={{ cursor: 'pointer' }}
                >
                  EMPEZAR
                </a>
              </div>
            </div>
            <div className="col-2">
              <div className="read-more-btn d-flex align-items-center justify-content-center">
                <a
                  className="edu-btn"
                  onClick={AbrirFormularioAmigos}
                  style={{ cursor: 'pointer' }}
                >
                  EMPEZAR
                </a>
              </div>
            </div>
            <div className="col-2">
              <div className="read-more-btn d-flex align-items-center justify-content-center">
                <a
                  className="edu-btn"
                  onClick={AbrirFormularioAmigos}
                  style={{ cursor: 'pointer' }}
                >
                  EMPEZAR
                </a>
              </div>
            </div>
            <div className="col-2">
              <div className="read-more-btn d-flex align-items-center justify-content-center">
                <a
                  className="edu-btn"
                  onClick={AbrirFormularioAmigos}
                  style={{ cursor: 'pointer' }}
                >
                  EMPEZAR
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*////////////////////////////  DONACIONES  //////////////////////////////////////////*/}

      <div id="section2" className="container eduvibe-animated-shape ">
        <div className="row g-5 pr--50 pl--50">
          <SectionTitle classes="text-center" title="Donaciones" />
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <p className="description text-center mb--30 has-dark-color ">
              Con tu aportación, nos ayudas a seguir divulgando las ciencias y
              promoviendo las maravillas de nuestra península.
            </p>

            <h5 className="description text-center mb--30 has-dark-color ">
              Si quieres donar favor de seguir el siguiente enlace.
            </h5>
          </ScrollAnimation>
        </div>

        <div className="row align-items-center mb--15">
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div>
              <form
                action="https://www.paypal.com/donate"
                method="post"
                target="_top"
              >
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="R2TQ9MFY4MU7A"
                />
                <input
                  type="image"
                  className="mx-auto d-block w-auto"
                  src="https://www.paypalobjects.com/es_XC/MX/i/btn/btn_donateCC_LG.gif"
                  border="0"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donar con el botón PayPal"
                />
                <img
                  alt=""
                  border="0"
                  src="https://www.paypal.com/es_MX/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
            </div>
          </ScrollAnimation>
        </div>
      </div>

      {/*////////////////////////////  DATOS TRANSFERENCIA  //////////////////////////////////////////*/}

      <div className="bg-color-darkblue">
        <div className="container eduvibe-animated-shape edu-section-gap">
          <div className="row">
            <div className="col-md-3 col-sm-1"></div>
            <div className="col-md-6 col-sm-10">
              <div className="content text-center">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h4 className="title">
                    O haz una transferencia a los siguientes datos:
                  </h4>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p className="description">
                    <b>Nombre: </b>Caracol Centro Científico y Cultural, A.C.
                    <br></br>
                    <b>Cuenta: </b>144397980203 <br></br>
                    <b>Clave Interbancaria: </b>030022900019574950 <br></br>
                    <b>Banco: </b>Banbajio
                  </p>
                </ScrollAnimation>
              </div>

              <div className="col-md-3 col-sm-1"></div>
            </div>
          </div>
        </div>
      </div>

      {/*////////////////////////////  ILUSTRACION MANO  //////////////////////////////////////////*/}

      <div className="container eduvibe-animated-shape edu-section-gap">
        <div className="row align-items-center gy-5 ">
          <div className="col-lg-6">
            <div className="row g-5">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <img
                  src={`${process.env.PUBLIC_URL}./images/colabora/mano_texto.jpg`}
                  alt="Icon Thumb"
                  className="mx-auto d-block"
                />
              </ScrollAnimation>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row g-5 pr--50 pl--50">
              <img
                src={`${process.env.PUBLIC_URL}./images/colabora/mano.jpg`}
                alt="Icon Thumb"
                className="mx-auto d-block"
              />
            </div>
          </div>
        </div>
      </div>

      {/*////////////////////////////  FORMA  //////////////////////////////////////////*/}

      <div className="bg-color-darkblue">
        <div className="container eduvibe-animated-shape edu-section-gap">
          <div className="row">
            <div className="col-md-3 col-sm-1"></div>
            <div className="col-md-6 col-sm-10">
              <div className="content text-center">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h4 className="title">
                    ¿Necesitas un recibo deducible de impuestos de tu donación?
                  </h4>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p className="description">
                    Llena el siguiente formulario y te lo enviaremos a la
                    dirección de correo que registres.
                  </p>
                </ScrollAnimation>
              </div>
              <div className="col-md-3 col-sm-1"></div>
            </div>
          </div>
          <div className="row align-items-center gy-5  ">
            <div className="col-3"></div>
            <div className="col-6">
              <div className="read-more-btn d-flex align-items-center justify-content-center">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <a
                    className="edu-btn"
                    onClick={AbrirFormularioDatos}
                    style={{ cursor: 'pointer', width: '180px' }}
                  >
                    MANDAR DATOS
                  </a>
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-3"></div>
          </div>
        </div>
      </div>

      {/*////////////////////////////  BANNER CHICO COLABORA  ////////////////////////////*/}

      <div       id="section3"></div>
      <BannerChicoColabora />

      {/*////////////////////////////  PRICE TIERS  //////////////////////////////////////////*/}

      <div
        className="container eduvibe-animated-shape "
        style={{ maxWidth: '970px' }}
      >
        <div className="row g-5 pr--50 pl--50 edu-section-gap-bottom">
          <SectionTitle
            classes="text-center"
            title="Elige tu categoría y el tamaño de tu placa"
          />
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <p className="description text-center mb--30 has-dark-color ">
              ¡Únete a la red de amigos de Caracol!, y sé parte de nuestra
              misión por hacer crecer un proyecto que nos pertenece a todos.
            </p>
          </ScrollAnimation>

          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div
              className="your-required-wrapper"
              style={{ width: 360, height: 55 , margin: "auto" }}
            >
              <SwitchSelector
                onChange={onChange}
                options={options}
                wrapperBorderRadius={5}
                optionBorderRadius={4}
                initialSelectedIndex={0}
                backgroundColor={'#353b48'}
                fontColor={'#f5f6fa'}
              />
            </div>
          </ScrollAnimation>

          <br/>
          <br/>
          <br/>
          
          <br/>
          <br/>
          <br/>

        </div>

        {/*//////////////price tier large*/}
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <div className="row g-5 align-items-center pricetier-style edu-section-gapBottom">
            <img
              className=""
              src={`${process.env.PUBLIC_URL}./images/colabora/tierra_banner.webp`}
              alt="Icon Thumb"
            />
            <div className="col-3  tier-text">
              <h3>Amigos de la Tierra</h3>
            </div>
            <div className="col-3  inner-square"></div>
            <div
              className="col-2  inner-square tier-tierra"
              onClick={AbrirFormularioDonacionesTierra}
            >
              {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/TierraD.png`}
                alt="Icon Thumb"
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/TierraA.png`}
                alt="Icon Thumb"
              />}
              
            </div>
            <div
              className="col-2  inner-square tier-tierra"
              onClick={AbrirFormularioDonacionesTierra}
            >
             {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/TierraE.png`}
                alt="Icon Thumb"
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/TierraB.png`}
                alt="Icon Thumb"
              />}
            </div>
            <div
              className="col-2  inner-square tier-tierra"
              onClick={AbrirFormularioDonacionesTierra}
            >
              {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/TierraF.png`}
                alt="Icon Thumb"
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/TierraC.png`}
                alt="Icon Thumb"
              />}
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <div className="row g-5 align-items-center pricetier-style edu-section-gapBottom">
            <img
              className=""
              src={`${process.env.PUBLIC_URL}./images/colabora/cielo_banner.webp`}
              alt="Icon Thumb"
            />
            <div className="col-3  tier-text">
              <h3>Amigos del Cielo</h3>
            </div>
            <div className="col-3  inner-square"></div>
            <div
              className="col-2  inner-square tier-cielo"
              onClick={AbrirFormularioDonacionesCielo}
            >
               {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/CieloD.png`}
                alt="Icon Thumb"
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/CieloA.png`}
                alt="Icon Thumb"
              />}
            </div>
            <div
              className="col-2  inner-square tier-cielo"
              onClick={AbrirFormularioDonacionesCielo}
            >
                {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/CieloE.png`}
                alt="Icon Thumb"
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/CieloB.png`}
                alt="Icon Thumb"
              />}
            </div>
            <div
              className="col-2  inner-square tier-cielo"
              onClick={AbrirFormularioDonacionesCielo}
            >
                {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/CieloF.png`}
                alt="Icon Thumb"
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/CieloC.png`}
                alt="Icon Thumb"
              />}
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <div className="row g-5 align-items-center pricetier-style edu-section-gapBottom">
            <img
              className=""
              src={`${process.env.PUBLIC_URL}./images/colabora/mar_banner.webp`}
              alt="Icon Thumb"
            />
            <div className="col-3  tier-text">
              <h3>Amigos del Mar</h3>
            </div>
            <div className="col-3  inner-square"></div>
            <div
              className="col-2  inner-square tier-mar"
              onClick={AbrirFormularioDonacionesMar}
            >
                {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/MarD.png`}
                alt="Icon Thumb"
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/MarA.png`}
                alt="Icon Thumb"
              />}
            </div>
            <div
              className="col-2  inner-square tier-mar"
              onClick={AbrirFormularioDonacionesMar}
            >
                 {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/MarE.png`}
                alt="Icon Thumb"
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/MarB.png`}
                alt="Icon Thumb"
              />}
            </div>
            <div
              className="col-2  inner-square tier-mar"
              onClick={AbrirFormularioDonacionesMar}
            >
                 {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/MarF.png`}
                alt="Icon Thumb"
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/MarC.png`}
                alt="Icon Thumb"
              />}
            </div>
          </div>
        </ScrollAnimation>

        {/*//////////////price tier small*/}
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <div className="row g-5 align-items-center pricetier-style-small-tierra edu-section-gapBottom">
            <div className="row ">
              <div className="col-12  tier-text">
                <h3>Amigos de la Tierra</h3>
              </div>
            </div>
            <div className="row tiersm">
              <div
                className="col-4  inner-square tier-tierra"
                onClick={AbrirFormularioDonacionesTierra}
              >
                 {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/TierraD.png`}
                alt="Icon Thumb"
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/TierraA.png`}
                alt="Icon Thumb"
              />}
              </div>
              <div
                className="col-4  inner-square tier-tierra"
                onClick={AbrirFormularioDonacionesTierra}
              >
                 {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/TierraE.png`}
                alt="Icon Thumb"
                onClick={AbrirFormularioDatos}
                  style={{ pointerEvents: 'all' }}
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/TierraB.png`}
                alt="Icon Thumb"
              />}

              
              </div>
              <div
                className="col-4  inner-square tier-tierra"
                onClick={AbrirFormularioDonacionesTierra}
              >
                    {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/TierraF.png`}
                alt="Icon Thumb"
                onClick={AbrirFormularioDatos}
                  style={{ pointerEvents: 'all' }}
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/TierraC.png`}
                alt="Icon Thumb"
              />}
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <div className="row g-5 align-items-center pricetier-style-small-cielo edu-section-gapBottom">
            <div className="row ">
              <div className="col-12  tier-text">
                <h3>Amigos del Cielo</h3>
              </div>
            </div>
            <div className="row tiersm">
              <div
                className="col-4  inner-square tier-cielo"
                onClick={AbrirFormularioDonacionesCielo}
              >
                {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/CieloD.png`}
                alt="Icon Thumb"
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/CieloA.png`}
                alt="Icon Thumb"
              />}
              </div>
              <div
                className="col-4  inner-square tier-tierra"
                onClick={AbrirFormularioDonacionesCielo}
              >
                 {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/CieloE.png`}
                alt="Icon Thumb"
                onClick={AbrirFormularioDatos}
                  style={{ pointerEvents: 'all' }}
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/CieloB.png`}
                alt="Icon Thumb"
              />}

              
              </div>
              <div
                className="col-4  inner-square tier-tierra"
                onClick={AbrirFormularioDonacionesCielo}
              >
                    {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/CieloF.png`}
                alt="Icon Thumb"
                onClick={AbrirFormularioDatos}
                  style={{ pointerEvents: 'all' }}
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/CieloC.png`}
                alt="Icon Thumb"
              />}
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <div className="row g-5 align-items-center pricetier-style-small-mar edu-section-gapBottom">
            <div className="row ">
              <div className="col-12  tier-text">
                <h3>Amigos del Mar</h3>
              </div>
            </div>
            <div className="row tiersm">
              <div
                className="col-4  inner-square tier-mar"
                onClick={AbrirFormularioDonacionesMar}
              >
                 {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/MarD.png`}
                alt="Icon Thumb"
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/MarA.png`}
                alt="Icon Thumb"
              />}
              </div>
              <div
                className="col-4  inner-square tier-tierra"
                onClick={AbrirFormularioDonacionesMar}
              >
                 {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/MarE.png`}
                alt="Icon Thumb"
                onClick={AbrirFormularioDatos}
                  style={{ pointerEvents: 'all' }}
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/MarB.png`}
                alt="Icon Thumb"
              />}

              
              </div>
              <div
                className="col-4  inner-square tier-tierra"
                onClick={AbrirFormularioDonacionesMar}
              >
                    {placasExtra ? <img
                src={`${process.env.PUBLIC_URL}./images/colabora/MarF.png`}
                alt="Icon Thumb"
                onClick={AbrirFormularioDatos}
                  style={{ pointerEvents: 'all' }}
              />: <img
                src={`${process.env.PUBLIC_URL}./images/colabora/MarC.png`}
                alt="Icon Thumb"
              />}
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>

      {/*////////////////////////////  FINAL  //////////////////////////////////////////*/}

      <ScrollAnimation
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}
      >
        <div className="row ">
          <div className="col-lg-3 col-md-2">
            <img
              src={`${process.env.PUBLIC_URL}./images/colabora/finalizq.jpg`}
              alt="Icon Thumb"
              style={{ float: 'left' }}
              className="d-none d-md-block"
            />
          </div>
          <div className="col-2 d-lg-none"></div>
          <div className="col-lg-6 col-md-4">
            <img
              src={`${process.env.PUBLIC_URL}./images/colabora/final.jpg`}
              alt="Icon Thumb"
              className="mx-auto d-block"
            />
          </div>
          <div className="col-2 d-lg-none"></div>
          <div className="col-lg-3 col-md-2">
            <img
              src={`${process.env.PUBLIC_URL}./images/colabora/finalder.jpg`}
              alt="Icon Thumb"
              style={{ float: 'right' }}
              className="d-none d-md-block"
            />
          </div>
        </div>
      </ScrollAnimation>
    </div>
  )
}

export default ContenidoColabora
