import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';


const BannerVisitanos = () => {
    return (
        <div className="slider-area banner-style-visitanos bg-image">
            <div className="wrapper d-flex align-items-center home-four-banner-wrapper">


                <div className="container eduvibe-animated-shape">

                    <div className="row g-5 align-items-center">
                        <div className="col-lg-2 order-2 order-lg-1" />
                        <div className="col-lg-8 order-2 order-lg-1">
                            <div className="inner mt_md--10 mt_sm--10">
                                <div className="content text-center">
                                    <h1 className="title">¡Visítanos y sé parte de <br></br>la experiencia Caracol!</h1>
                                    <p className="description">Jueves a domingo <br></br>10:00 am a 5:00 pm </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
               
        </div>

        
    )
}

export default BannerVisitanos;