import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerPoliticaPrivacidad from '../../components/banner/BannerPoliticaPrivacidad';
import FooterTwo from '../../common/footer/FooterTwo';
import ContenidoPoliticaDePrivacidad from '../../components/ContenidoPoliticaDePrivacidad';


const PoliticaPrivacidad = () => {
    return (
        <>
            <SEO title="Caracol Museo de Ciencias" />

            <HeaderTwo styles="header-caracol header-style-2" searchDisable />            
            <BannerPoliticaPrivacidad />
            <ContenidoPoliticaDePrivacidad />
            <FooterTwo />   
        </>
    )
}

export default PoliticaPrivacidad;