import React, { Component } from 'react'
import CountUp from 'react-countup'
import ScrollAnimation from 'react-animate-on-scroll'
import TrackVisibility from 'react-on-screen'
import SectionTitle from '../../components/sectionTitle/SectionTitle'
import GoogleMap from '../../components/contact/GoogleMap'

import { PayPalButton } from 'react-paypal-button-v2'

export default class ContenidoVisitanos extends Component {
  state = {
    total: 0,
    numBoletos: 0,
    bol_general: 0,
    bol_tercera: 0,
    bol_estudiantes: 0,
    bol_discapacidad: 0,
    bol_ninos: 0,
    pago_exitoso: false,
    quita_boton: false,
    paymet_message: '',
  }

  increment = (tipo) => {
    if (tipo == 'general') {
      this.setState({
        total: this.state.total + 80,
        numBoletos: this.state.numBoletos + 1,
        bol_general: this.state.bol_general + 1,
      })
    }
    if (tipo == 'tercera') {
      this.setState({
        total: this.state.total + 60,
        numBoletos: this.state.numBoletos + 1,
        bol_tercera: this.state.bol_tercera + 1,
      })
    }
    if (tipo == 'estudiantes') {
      this.setState({
        total: this.state.total + 60,
        numBoletos: this.state.numBoletos + 1,
        bol_estudiantes: this.state.bol_estudiantes + 1,
      })
    }
    if (tipo == 'discapacidad') {
      this.setState({
        total: this.state.total + 60,
        numBoletos: this.state.numBoletos + 1,
        bol_discapacidad: this.state.bol_discapacidad + 1,
      })
    }

    if (tipo == 'ninos') {
      this.setState({
        total: this.state.total + 50,
        numBoletos: this.state.numBoletos + 1,
        bol_ninos: this.state.bol_ninos + 1,
      })
    }
  }

  decrement = (tipo) => {
    if (tipo == 'general' && this.state.bol_general - 1 >= 0) {
      this.setState({
        total: this.state.total - 80,
        numBoletos: this.state.numBoletos - 1,
        bol_general: this.state.bol_general - 1,
      })
    }
    if (tipo == 'tercera' && this.state.bol_tercera - 1 >= 0) {
      this.setState({
        total: this.state.total - 60,
        numBoletos: this.state.numBoletos - 1,
        bol_tercera: this.state.bol_tercera - 1,
      })
    }
    if (tipo == 'estudiantes' && this.state.bol_estudiantes - 1 >= 0) {
      this.setState({
        total: this.state.total - 60,
        numBoletos: this.state.numBoletos - 1,
        bol_estudiantes: this.state.bol_estudiantes - 1,
      })
    }
    if (tipo == 'discapacidad' && this.state.bol_discapacidad - 1 >= 0) {
      this.setState({
        total: this.state.total - 60,
        numBoletos: this.state.numBoletos - 1,
        bol_discapacidad: this.state.bol_discapacidad - 1,
      })
    }

    if (tipo == 'ninos' && this.state.bol_ninos - 1 >= 0) {
      this.setState({
        total: this.state.total - 50,
        numBoletos: this.state.numBoletos - 1,
        bol_ninos: this.state.bol_ninos - 1,
      })
    }
  }

  postSuccess = async (detailsPay, dataPay) => {
    // const Cosmic = require('cosmicjs')
    // const api = Cosmic()
    // const bucket = api.bucket({
    //   slug: process.env.REACT_APP_COSMIC_BUCKET || 'caracol-production',
    //   read_key:
    //     process.env.REACT_APP_COSMIC_READ_KEY ||
    //     'XkAbD0A5dngRay6VvjM1ZhMc1irjQiqJIfGzTlCjHDLy8EvlrM',
    //   write_key:
    //     process.env.REACT_APP_COSMIC_READ_KEY ||
    //     'rckb0jgHZwJN5E5i0cA5rrqUVF0x19GJsktYpdwxSjxQxeMjkk',
    // })

    // var detalleDeCompra = ''
    // if (this.state.bol_general > 0)
    //   detalleDeCompra += this.state.bol_general + ' entradas generales\n'
    // if (this.state.bol_tercera > 0)
    //   detalleDeCompra +=
    //     this.state.bol_tercera + ' entradas para personas de la 3ra edad\n'
    // if (this.state.bol_estudiantes > 0)
    //   detalleDeCompra +=
    //     this.state.bol_estudiantes + ' entradas de estudiante\n'
    // if (this.state.bol_discapacidad > 0)
    //   detalleDeCompra +=
    //     this.state.bol_discapacidad +
    //     ' entradas para personas con discapacidad\n'
    // if (this.state.bol_ninos > 0)
    //   detalleDeCompra += this.state.bol_ninos + ' entradas para niños\n'

    // const data = await bucket
    //   .addObject({
    //     title: 'Pago exitoso',
    //     type_slug: 'boletos', // find this in your Object's settings
    //     status: 'published', // e.g. "draft" / "published"
    //     metafields: [
    //       {
    //         key: 'id_de_transaccion',
    //         type: 'text',
    //         title: 'ID de transacción',
    //         value: dataPay.orderID,
    //       },
    //       {
    //         key: 'cantidad_de_boletos',
    //         type: 'text',
    //         title: 'Cantidad de boletos',
    //         value: this.state.numBoletos,
    //       },
    //       {
    //         key: 'nombre',
    //         type: 'text',
    //         title: 'Nombre',
    //         value:
    //           detailsPay.payer.name.given_name +
    //           ' ' +
    //           detailsPay.payer.name.surname,
    //       },
    //       {
    //         key: 'email',
    //         type: 'text',
    //         title: 'Email',
    //         value: detailsPay.payer.email_address,
    //       },
    //       {
    //         key: 'fecha',
    //         type: 'text',
    //         title: 'Fecha',
    //         value: detailsPay.create_time,
    //       },
    //       {
    //         key: 'monto',
    //         type: 'text',
    //         title: 'Monto',
    //         value: this.state.total,
    //       },
    //       {
    //         key: 'detalle_de_compra',
    //         type: 'textarea',
    //         title: 'Detalle de compra',
    //         value: detalleDeCompra,
    //       },
    //     ],
    //   })
    //   .then((data) => {
    //     console.log(data)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
  }

  render() {
    return (
      <>
        <div
          id="section1"
          className={`eduvibe-home-two-counter bg-color-white `}
        >
          <section id="tickets" className="section-padd-100">
            <div className="container-fluid">
              <div className="container-mediano">
                <div className="section-title">
                  <h2>Adquiere tus boletos</h2>
                  <div className="tickets-bottom">
                  <h5>Museo Caracol es una asociación civil sin fines de lucro. El cobro de entrada es una cuota de recuperación que se utiliza para dar mantenimiento a 
                    nuestras salas, exposiciones e instalaciones.</h5>
                  </div>
                </div>
               
                <div className="row">
                  <div className="columna">
                    <div className="card text-center">
                      <div className="title">
                        <img
                          src={`${process.env.PUBLIC_URL}./images/iconosboletos/general.png`}
                          alt="Icon Thumb"
                        />
                        <h2>
                          GENERAL <br />
                          <wbr />
                        </h2>
                      </div>
                      <div className="bottom-bar"></div>
                      <div className="price">
                        <h4>$80 M.N.</h4>
                      </div>
                      <div className="tickets-right">
                        <div className="input-group inline-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn-minus"
                              onClick={() => this.decrement('general')}
                            >
                              <i className="fa fa-minus"></i>
                            </button>
                          </div>
                          <input
                            className="form-control quantity"
                            min="0"
                            name="quantity"
                            value={this.state.bol_general}
                            type="number"
                            readOnly
                          />
                          <div className="input-group-append">
                            <button
                              className="btn-plus"
                              onClick={() => this.increment('general')}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="columna">
                    <div className="card text-center">
                      <div className="title">
                        <img
                          src={`${process.env.PUBLIC_URL}./images/iconosboletos/terceraedad.png`}
                          alt="Icon Thumb"
                        />
                        <h2>
                          TERCERA
                          <br /> EDAD
                        </h2>
                      </div>
                      <div className="bottom-bar"></div>
                      <div className="price">
                        <h4>$60 M.N.</h4>
                      </div>

                      <div className="tickets-right">
                        <div className="input-group inline-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn-minus"
                              onClick={() => this.decrement('tercera')}
                            >
                              <i className="fa fa-minus"></i>
                            </button>
                          </div>
                          <input
                            className="form-control quantity"
                            min="0"
                            name="quantity"
                            value={this.state.bol_tercera}
                            type="number"
                            readOnly
                          />
                          <div className="input-group-append">
                            <button
                              className="btn-plus"
                              onClick={() => this.increment('tercera')}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="columna">
                    <div className="card text-center">
                      <div className="title">
                        <img
                          src={`${process.env.PUBLIC_URL}./images/iconosboletos/estudiantes.png`}
                          alt="Icon Thumb"
                        />

                        <h2>
                          ESTUDIANTES <br />
                          <wbr />
                        </h2>
                      </div>
                      <div className="bottom-bar"></div>
                      <div className="price">
                        <h4>$60 M.N.</h4>
                      </div>

                      <div className="tickets-right">
                        <div className="input-group inline-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn-minus"
                              onClick={() => this.decrement('estudiantes')}
                            >
                              <i className="fa fa-minus"></i>
                            </button>
                          </div>
                          <input
                            className="form-control quantity"
                            min="0"
                            name="quantity"
                            value={this.state.bol_estudiantes}
                            type="number"
                            readOnly
                          />
                          <div className="input-group-append">
                            <button
                              className="btn-plus"
                              onClick={() => this.increment('estudiantes')}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="columna">
                    <div className="card text-center">
                      <div className="title">
                        <img
                          src={`${process.env.PUBLIC_URL}./images/iconosboletos/pcd.png`}
                          alt="Icon Thumb"
                        />

                        <h2>PERSONAS CON DISCAPACIDAD</h2>
                      </div>
                      <div className="bottom-bar"></div>
                      <div className="price">
                        <h4>$60 M.N.</h4>
                      </div>

                      <div className="tickets-right">
                        <div className="input-group inline-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn-minus"
                              onClick={() => this.decrement('discapacidad')}
                            >
                              <i className="fa fa-minus"></i>
                            </button>
                          </div>
                          <input
                            className="form-control quantity"
                            min="0"
                            name="quantity"
                            value={this.state.bol_discapacidad}
                            type="number"
                            readOnly
                          />
                          <div className="input-group-append">
                            <button
                              className="btn-plus"
                              onClick={() => this.increment('discapacidad')}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="columna">
                    <div className="card text-center">
                      <div className="title">
                        <img
                          src={`${process.env.PUBLIC_URL}./images/iconosboletos/ninos.png`}
                          alt="Icon Thumb"
                        />

                        <h2>
                          NIÑOS <br />
                          (6 a 12)
                        </h2>
                      </div>
                      <div className="bottom-bar"></div>
                      <div className="price">
                        <h4>$50 M.N.</h4>
                      </div>

                      <div className="tickets-right">
                        <div className="input-group inline-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn-minus"
                              onClick={() => this.decrement('ninos')}
                            >
                              <i className="fa fa-minus"></i>
                            </button>
                          </div>
                          <input
                            className="form-control quantity"
                            min="0"
                            name="quantity"
                            value={this.state.bol_ninos}
                            type="number"
                            readOnly
                          />
                          <div className="input-group-append">
                            <button
                              className="btn-plus"
                              onClick={() => this.increment('ninos')}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="columna">
                    <div className="card text-center">
                      <div className="title">
                        <img
                          src={`${process.env.PUBLIC_URL}./images/iconosboletos/bebe.png`}
                          alt="Icon Thumb"
                        />

                        <h2>
                          NIÑOS <br />
                          (0 a 5)
                        </h2>
                      </div>
                      <div className="bottom-bar"></div>
                      <div className="price">
                        <h4>GRATIS</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*<div className="tickets">
								<div className="tickets-box">
									<div className="tickets-left">
										<p>Admisión General</p>
									</div>
									<div className="tickets-center">
										<p>$60.00 m.n.</p>
									</div>
									<div className="tickets-right">
										<div className="input-group inline-group">
											<div className="input-group-prepend">
												<button className="btn-minus" onClick={() => this.decrement("general")} >
													<i className="fa fa-minus"></i>
												</button>
											</div>
											<input className="form-control quantity" min="0" name="quantity" value={this.state.bol_general} type="number" readOnly />
											<div className="input-group-append">
												<button className="btn-plus" onClick={() => this.increment("general")} >
													<i className="fa fa-plus"></i>
												</button>
											</div>
										</div>
									</div>
								</div>

								<div className="tickets-box">
									<div className="tickets-left">
										<p>Tercera Edad</p>
									</div>
									<div className="tickets-center">
										<p>$40.00 m.n.</p>
									</div>
									<div className="tickets-right">
										<div className="input-group inline-group">
											<div className="input-group-prepend">
												<button className="btn-minus" onClick={() => this.decrement("tercera")} >
													<i className="fa fa-minus"></i>
												</button>
											</div>
											<input className="form-control quantity" min="0" name="quantity" value={this.state.bol_tercera} type="number" readOnly />
											<div className="input-group-append">
												<button className="btn-plus" onClick={() => this.increment("tercera")} >
													<i className="fa fa-plus"></i>
												</button>
											</div>
										</div>
									</div>
								</div>

								<div className="tickets-box">
									<div className="tickets-left">
										<p>Estudiantes</p>
									</div>
									<div className="tickets-center">
										<p>$40.00 m.n.</p>
									</div>
									<div className="tickets-right">
										<div className="input-group inline-group">
											<div className="input-group-prepend">
												<button className="btn-minus" onClick={() => this.decrement("estudiantes")} >
													<i className="fa fa-minus"></i>
												</button>
											</div>
											<input className="form-control quantity" min="0" name="quantity" value={this.state.bol_estudiantes} type="number" readOnly />
											<div className="input-group-append">
												<button className="btn-plus" onClick={() => this.increment("estudiantes")} >
													<i className="fa fa-plus"></i>
												</button>
											</div>
										</div>
									</div>
								</div>

								<div className="tickets-box">
									<div className="tickets-left">
										<p>Personas con Discapacidad</p>
									</div>
									<div className="tickets-center">
										<p>$40.00 m.n.</p>
									</div>
									<div className="tickets-right">
										<div className="input-group inline-group">
											<div className="input-group-prepend">
												<button className="btn-minus" onClick={() => this.decrement("discapacidad")} >
													<i className="fa fa-minus"></i>
												</button>
											</div>
											<input className="form-control quantity" min="0" name="quantity" value={this.state.bol_discapacidad} type="number" readOnly />
											<div className="input-group-append">
												<button className="btn-plus" onClick={() => this.increment("discapacidad")} >
													<i className="fa fa-plus"></i>
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="tickets-box">
									<div className="tickets-left">
										<p>Niños (6-12)</p>
									</div>
									<div className="tickets-center">
										<p>$30.00 m.n.</p>
									</div>
									<div className="tickets-right">
										<div className="input-group inline-group">
											<div className="input-group-prepend">
												<button className="btn-minus" onClick={() => this.decrement("ninos")} >
													<i className="fa fa-minus"></i>
												</button>
											</div>
											<input className="form-control quantity" min="0" name="quantity" value={this.state.bol_ninos} type="number" readOnly />
											<div className="input-group-append">
												<button className="btn-plus" onClick={() => this.increment("ninos")} >
													<i className="fa fa-plus"></i>
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="tickets-box">
									<div className="tickets-left">
										<p>Niños (0-5)</p>
									</div>
									<div className="tickets-center">
										<p>Gratis</p>
									</div>
									<div className="tickets-right"></div>
								</div>
							</div>*/}
          </section>
          <div className="tickets-bottom">
            <h2>Total - ${this.state.total} MXN</h2>
            <h4
              className={
                this.state.pago_exitoso ? 'has-green-color' : 'has-red-color'
              } dangerouslySetInnerHTML={{__html: this.state.paymet_message}}
            >
            </h4>
            {this.state.quita_boton ? null : (
              <PayPalButton 
                amount={this.state.total}
                // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                onSuccess={(details, data) => {
                  this.postSuccess(details, data)

                  var detalleDeCompra = 'Pago Exitoso</br>'
                  if (this.state.bol_general > 0)
                    detalleDeCompra +=
                      this.state.bol_general + ' entradas generales</br>'
                  if (this.state.bol_tercera > 0)
                    detalleDeCompra +=
                      this.state.bol_tercera +
                      ' entradas para personas de la 3ra edad</br>'
                  if (this.state.bol_estudiantes > 0)
                    detalleDeCompra +=
                      this.state.bol_estudiantes + ' entradas de estudiante</br>'
                  if (this.state.bol_discapacidad > 0)
                    detalleDeCompra +=
                      this.state.bol_discapacidad +
                      ' entradas para personas con discapacidad</br>'
                  if (this.state.bol_ninos > 0)
                    detalleDeCompra +=
                      this.state.bol_ninos + ' entradas para niños</br>'

					  detalleDeCompra +='A nombre de '+ details.payer.name.given_name + ' ' + details.payer.name.surname

                  this.setState({
                    pago_exitoso: true,
                    quita_boton: true,
                    paymet_message: detalleDeCompra,
                  })
                }}
                onError={(err) => {
                  console.log('onError: err=', err)
                  this.setState({
                    pago_exitoso: false,
                    paymet_message:
                      'Error al pagar' +
                      (this.state.total == 0
                        ? ', no tiene boletos en su canasta'
                        : ''),
                  })
                }}
                options={{
                  currency: 'MXN',
                }}
              />
            )}
          </div>
        </div>

        <section id="content-box" className="section-padd-50">
          <div id="section2" className="container">
            <div className="row dis-flex">
              <div className="col-md-6">
                <h4>Norte a Sur</h4>
                <p>
                  Al finalizar la carretera Tijuana-Ensenada, tomar la calle
                  Teniente Azueta y continuar por el Blvd. Costero. A la altura
                  del Riviera de Ensenada, dar vuelta a la derecha hasta
                  terminar la calle y continuar por el estacionamiento. A tu
                  derecha verás la Taquilla.
                </p>
              </div>
              <div className="col-md-6">
                <h4>Sur a Norte</h4>
                <p>
                  Continuar por la carretera Transpeninsular, dar vuelta a la
                  izquierda en la calle Las Palmas hasta llegar al Blvd. Costero
                  y dar vuelta a la derecha. A la altura del Centro Estatal de
                  las Artes Ensenada, dar vuelta a la izquierda hasta terminar
                  la calle, seguir por la derecha hasta ver la Taquilla.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3388.816466636048!2d-116.62254968484106!3d31.857209181259524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d8926e2003c3e9%3A0x691bfd526f39c173!2sCaracol%20Centro%20Cient%C3%ADfico%20y%20Cultural%20A.C!5e0!3m2!1sen!2sbd!4v1649192491657!5m2!1sen!2sbd"
            width="100%"
            height="550"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </section>
      </>
    )
  }
}
