import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';


const BannerColabora = () => {
    return (
        <div className="slider-area banner-style-colabora bg-image">
            <div className="wrapper d-flex align-items-center home-four-banner-wrapper">


                <div className="container eduvibe-animated-shape">

                    <div className="row g-5 align-items-center">
                        <div className="col-lg-2 order-2 order-lg-1" />
                        <div className="col-lg-8 order-2 order-lg-1">
                            <div className="inner mt_md--10 mt_sm--10">
                                <div className="content text-center">
                                <h1 className="pre-title">Caracol es de todos.</h1>
                                <h1 className="title">¡Apoya el futuro de Caracol!</h1>
                                    <p className="description">Estamos siempre haciendo nuestro
                                                                mejor esfuerzo para promover la
                                                                divulgación de la ciencia, pero
                                                                necesitamos tu ayuda. Caracol es
                                                                una asociación civil que ha logrado
                                                                sostenerse gracias a la generosidad
                                                                de muchas personas, organizaciones
                                                                y empresas. ¡Tú también puedes ser

                                                                parte!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
               
        </div>

        
    )
}

export default BannerColabora;