import { useState, useEffect } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import Nav from './Nav';
import HeaderSticky from './HeaderSticky';
import ResponsiveMenu from './ResponsiveMenu';
import ScrollTopButton from '../footer/ScrollTopButton';
import Horario from '../Horario';

const HeaderTwo = ({ styles, disableSticky, searchDisable, buttonStyle }) => {
    const [offcanvasShow, setOffcanvasShow] = useState(false);
    const [searchPopup, setSearchPopup] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow(prevState => !prevState);
    }

    var tt =  <Horario /> ;

    useEffect(() => {

        // var d = new Date().getDay();
        // var titles = ['Cerrado hoy', 'Cerrado hoy', 'Cerrado hoy', 'Abierto hoy<br />hasta las 5pm', 'Abierto hoy<br />hasta las 5pm', 'Abierto hoy<br />hasta las 5pm', 'Abierto hoy<br />hasta las 5pm',];
        
        
        // console.log("gheader2........");
        // //console.log(<Horario />);
        // console.log(tt.state);

        // document.getElementById("texto-horario").innerHTML = titles[d - 1];
        // //document.getElementById("texto-horario").innerHTML = <Horario /> ;

        if (document.getElementById("texto-horario").innerHTML=='Cerrado hoy')
            document.getElementById("texto-horario").style.lineHeight = "32px";
        else
            document.getElementById("texto-horario").style.lineHeight = "24px";
    });

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -70; 
        setTimeout(function () {
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
        },2);
        
    }


    const AbrirFacebook = () => {
        window.open("https://www.facebook.com/museocaracol")
    }
    const AbrirTwitter = () => {
        window.open("https://twitter.com/MuseoCaracol")
    }
    const AbrirInstagram = () => {
        window.open("https://www.instagram.com/museocaracol/")
    }
    const AbrirYoutube = () => {
        window.open("https://www.youtube.com/channel/UCAaBLwSMnaplsTDWlXOlhWw")
    }
    const AbrirTikTok = () => {
        window.open("https://www.tiktok.com/@museocaracol")
    }
    const sticky = HeaderSticky(200);
    const classes = `header-default ${sticky ? 'sticky' : ''}`;
    const stickyStatus = disableSticky ? '' : ' header-sticky';
    return (
        <>
       
            <div className="top-banner">
                <NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/covid`}>Información sobre el covid-19 — Conoce más sobre el protocolo de la nueva normalidad y cómo cuidarte{/* &nbsp;&nbsp; <span>Saber mas</span>*/}</NavHashLink>
            </div>
            <header className={`edu-header ${stickyStatus} ${styles || ''} ${classes || ''}`}>

                <div className="row align-items-center">
                    <div className="col-lg-4 col-xl-1 col-md-6 col-6">
                        <div className="logo">
                            <NavHashLink scroll={el => scrollWithOffset(el)} to={process.env.PUBLIC_URL + "/"} >
                                <img className="logo-light" src="/images/logo/logo_100x85.png" alt="Main Logo" />
                            </NavHashLink>
                        </div>
                    </div>

                    <div className="col-lg-6 d-none d-xl-block">
                        <nav className="mainmenu-nav d-none d-lg-block">
                            <Nav />
                        </nav>
                    </div>

                    <div className="col-lg-8 col-xl-5 col-md-6 col-6">
                        <div className="header-right d-flex justify-content-end">
                            <div className="header-menu-bar">

                                <div className="quote-icon quote-search d-none d-xl-block">
                                    <button className="white-icon-menu search-trigger header-search" onClick={AbrirFacebook}>
                                        <i className="ri-facebook-line"></i>
                                    </button>
                                </div>
                                <div className="quote-icon quote-search d-none d-xl-block">
                                    <button className="white-icon-menu search-trigger header-search" onClick={AbrirTwitter}>
                                        <i className="ri-twitter-line"></i>
                                    </button>
                                </div>
                                <div className="quote-icon quote-search d-none d-xl-block">
                                    <button className="white-icon-menu search-trigger header-search" onClick={AbrirInstagram}>
                                        <i className="ri-instagram-line"></i>
                                    </button>
                                </div>
                                <div className="quote-icon quote-search d-none d-xl-block">
                                    <button className="white-icon-menu search-trigger header-search" onClick={AbrirYoutube}>
                                        <i className="ri-youtube-line"></i>
                                    </button>
                                </div>
                                <div className="quote-icon quote-search d-none d-xl-block">
                                    <button className="white-icon-menu search-trigger header-search" onClick={AbrirTikTok}>
                                        <i className="icon-tiktok"></i>
                                    </button>
                                </div>
                                <div className="d-none d-xl-block">
                                    <NavHashLink scroll={el => scrollWithOffset(el)} id="texto-horario" className={`edu-btn btn-no-outline left-icon header-button ${buttonStyle || ''}`} to={process.env.PUBLIC_URL + "/Visitanos"}>
                                    <Horario />
                                    </NavHashLink>
                                </div>

                                <div className="quote-icon quote-user d-none d-xl-block ml--15 ml_sm--5">
                                    <NavHashLink scroll={el => scrollWithOffset(el)} className={`edu-btn btn-medium-outline left-icon header-button ${buttonStyle || ''}`} to={process.env.PUBLIC_URL + "/Visitanos#section1"}>
                                        <i className="ri-ticket-line"></i>BOLETOS
                                    </NavHashLink>
                                </div>
                            </div>

                            <div className="mobile-menu-bar ml--15 ml_sm--5 d-block d-xl-none">
                                <div className="hamberger">
                                    <button className="no-box-icon hamberger-button header-menu" onClick={onCanvasHandler}>
                                        <i className="ri-menu-line"></i>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </header>
            <ul className="floating-menu">
                <li>
                    <div className="tooltip-icon-header">

                        <NavHashLink scroll={el => scrollWithOffset(el)} to={process.env.PUBLIC_URL + "/Visitanos#section1"}>
                            <img className="one" src="/images/menu-flotante/boletos.png" alt="Footer Logo" />
                        </NavHashLink>
                        <div className="tooltip-text-header">
                            Boletos
                        </div>
                    </div>
                </li>
                <li>
                    <div className="tooltip-icon-header">

                        <NavHashLink scroll={el => scrollWithOffset(el)} to={process.env.PUBLIC_URL + "/salas#section1"}>
                            <img className="one" src="/images/menu-flotante/cielo.png" alt="Footer Logo" />
                        </NavHashLink>
                        <div className="tooltip-text-header segundo">
                            Sala del cielo 
                        </div>
                    </div>
                </li>
                <li>
                    <div className="tooltip-icon-header">

                        <NavHashLink scroll={el => scrollWithOffset(el)} to={process.env.PUBLIC_URL + "/salas#section2"} >
                            <img className="one" src="/images/menu-flotante/tierra.png" alt="Footer Logo" />
                        </NavHashLink>
                        <div className="tooltip-text-header tercero">
                            Sala de la tierra
                        </div>
                    </div>
                </li>
                <li>
                    <div className="tooltip-icon-header">

                        <NavHashLink scroll={el => scrollWithOffset(el)} to={process.env.PUBLIC_URL + "/salas#section3"}>
                            <img className="one" src="/images/menu-flotante/mar.png" alt="Footer Logo" />
                        </NavHashLink>
                        <div className="tooltip-text-header cuarto">
                            Sala del mar
                        </div>
                    </div>
                </li>

            </ul>

            <ResponsiveMenu
                show={offcanvasShow}
                onClose={onCanvasHandler}
                showSearch={searchPopup}
            />
            <ScrollTopButton />

        </>
    )
}

export default HeaderTwo;