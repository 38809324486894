import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerVideo from '../../components/banner/BannerVideo'
import BannerCielo from '../../components/banner/BannerCielo';
import BannerMar from '../../components/banner/BannerMar';
import BannerTierra from '../../components/banner/BannerTierra';
import HomeGalerias from '../../components/HomeGalerias';
import FooterTwo from '../../common/footer/FooterTwo';
import BannerChicoGalerias from '../../components/banner/BannerChicoGalerias';

const Home = () => {
    return (
        <>
            <SEO title="Caracol Museo de Ciencias" />

            <HeaderTwo styles="header-caracol header-style-2" searchDisable />

            <BannerVideo /> 
            
            <BannerCielo />
            <BannerTierra />
            <BannerMar />
           {/* <BannerChicoGalerias /> 
            <HomeGalerias/>*/}

            <FooterTwo />   

        </>
    )
}

export default Home;