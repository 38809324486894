import React from 'react';
import CountUp from 'react-countup';
import ScrollAnimation from 'react-animate-on-scroll';
import TrackVisibility from 'react-on-screen';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { HashLink as Link } from 'react-router-hash-link';






const LinkBannerConocenos = ({ wrapperClass }) => {
    return (
        <>
            <div className="container-fluid directrio-section">
                <div className="row">
                    <div className="col-lg-6 p-0 hover">
                    <Link to={process.env.PUBLIC_URL + `/Directorio`}>
                        <div className="directorio-conocenos-bg d-bg">
                            <div className="trans-abs">
                                <div className="trans-text">
                                    <h3 className="heading  text-center">
                                        DIRECTORIO
                                    </h3>
                                    <div className="e-hover">
                                        <div className="font-weight-bold text-blue">+ SABER MAS</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    </div>
                    <div className="col-lg-6 p-0">
                    <Link to={process.env.PUBLIC_URL + `/Transparencia`}>
                        <div className="transparencia-conocenos-bg d-bg">

                            <div className="trans-abs">
                                <div className="trans-text">
                                    <h3 className="heading  text-center">
                                        TRANSPARENCIA
                                    </h3>
                                    <div className="e-hover">
                                        <div className="font-weight-bold text-blue">+ SABER MAS</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LinkBannerConocenos;