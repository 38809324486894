import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import FooterTwo from '../../common/footer/FooterTwo';
import QRContent from '../innerpages/QRContent';

const QRPage = () => {
    return (
        <>
            <SEO title="Caracol Museo de Ciencias" />

            <HeaderTwo styles="header-caracol header-style-2" searchDisable />

            <QRContent /> 
            
            <FooterTwo />   

        </>
    )
}

export default QRPage;