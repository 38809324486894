import React from 'react'
import { NavHashLink } from 'react-router-hash-link'
import ScrollAnimation from 'react-animate-on-scroll'

const BannerCielo = () => {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
    const yOffset = -70
    setTimeout(function () {
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
    }, 2)
  }

  return (
    <div className="slider-area banner-style-cielo bg-image">
      <div className="wrapper d-flex align-items-center home-four-banner-wrapper">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-lg-2 order-2 order-lg-1" />
            <div className="col-lg-8 order-2 order-lg-1">
              <div className="inner mt_md--10 mt_sm--10">
                <div className="content text-center">
                  <span className="pre-title">Sala del cielo y Planetario</span>
                  <h1 className="title">Observa nuestro mundo desde arriba</h1>
                  <p className="description">
                    Una aventura desde las alturas, donde podrás descubrir un
                    sinfín de sorpresas que ofrece el cielo y todos los
                    elementos asombrosos que rodea su espacio.
                  </p>
                  <div className="read-more-btn">
                    <NavHashLink
                      scroll={(el) => scrollWithOffset(el)}
                      className="edu-btn"
                      to={process.env.PUBLIC_URL + '/salas#section1'}
                    >
                      VER MÁS<i className="icon-arrow-right-line-right"></i>
                    </NavHashLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 order-2 order-lg-1" />
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-image-sala shape-image-virgo">
            <ScrollAnimation animateIn="fadeInUp" delay={300}>
              <img src="/images/imgsHome/Virgo.webp" alt="Shape Thumb" />
            </ScrollAnimation>
            <div className="shape-nombre">
              <ScrollAnimation animateIn="fadeInUp" delay={600}>
                <img
                  src="/images/imgsHome/nombreVirgo.webp"
                  alt="Shape Thumb"
                />
              </ScrollAnimation>
            </div>
          </div>
          <div className="shape-image-sala shape-image-hercules">
            <ScrollAnimation animateIn="fadeInUp" delay={300}>
              <img src="/images/imgsHome/Hercules.webp" alt="Shape Thumb" />
            </ScrollAnimation>
            <div className="shape-nombre">
              <ScrollAnimation animateIn="fadeInUp" delay={600}>
                <img
                  src="/images/imgsHome/nombreHercules.webp"
                  alt="Shape Thumb"
                />
              </ScrollAnimation>
            </div>
          </div>
          <div className="shape-image-sala shape-image-hydra">
            <ScrollAnimation animateIn="fadeInUp" delay={300}>
              <img src="/images/imgsHome/Hydra.webp" alt="Shape Thumb" />
            </ScrollAnimation>
            <div className="shape-nombre">
              <ScrollAnimation animateIn="fadeInUp" delay={600}>
                <img
                  src="/images/imgsHome/nombreHydra.webp"
                  alt="Shape Thumb"
                />
              </ScrollAnimation>
            </div>
          </div>
          <div className="shape-image-sala shape-image-centaurus">
            <ScrollAnimation animateIn="fadeInUp" delay={300}>
              <img src="/images/imgsHome/Centaurus.webp" alt="Shape Thumb" />
            </ScrollAnimation>
            <div className="shape-nombre">
              <ScrollAnimation animateIn="fadeInUp" delay={600}>
                <img
                  src="/images/imgsHome/nombreCentaurus.webp"
                  alt="Shape Thumb"
                />
              </ScrollAnimation>
            </div>
          </div>
          <div className="shape-image-sala shape-image-ursamenor">
            <ScrollAnimation animateIn="fadeInUp" delay={300}>
              <img src="/images/imgsHome/UrsaMenor.webp" alt="Shape Thumb" />
            </ScrollAnimation>
            <div className="shape-nombre">
              <ScrollAnimation animateIn="fadeInUp" delay={600}>
                <img
                  src="/images/imgsHome/nombreUrsaMenor.webp"
                  alt="Shape Thumb"
                />
              </ScrollAnimation>
            </div>
          </div>
          <div className="shape-image-sala shape-image-ursamayor">
            <ScrollAnimation animateIn="fadeInUp" delay={300}>
              <img src="/images/imgsHome/UrsaMayor.webp" alt="Shape Thumb" />
            </ScrollAnimation>
            <div className="shape-nombre">
              <ScrollAnimation animateIn="fadeInUp" delay={600}>
                <img
                  src="/images/imgsHome/nombreUrsaMayor.webp"
                  alt="Shape Thumb"
                />
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerCielo
