import React from 'react';
import { Link } from 'react-router-dom';
import ScrollTopButton from './ScrollTopButton';

const FooterOne = () => {

    const onSearchHandler = () => {

    }
    return (
        <>
            <footer className="eduvibe-footer-one edu-footer footer-style-default">
                <div className="footer-top">
                    <div className="container eduvibe-animated-shape">
                        <div className="row">


                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget explore-widget">
                                    <h5 className="widget-title">Explora</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to="/about-us-1"><i className="icon-Double-arrow"></i>Planetario</Link></li>
                                            <li><Link to="/event-grid"><i className="icon-Double-arrow"></i>Eventos</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget quick-link-widget">
                                    <h5 className="widget-title">Enlaces útiles</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to="/contact-us"><i className="icon-Double-arrow"></i>Contáctanos</Link></li>
                                            <li><Link to="/instructor-details/james-carlson"><i className="icon-Double-arrow"></i>Visítanos</Link></li>
                                            <li><Link to="/purchase-guide"><i className="icon-Double-arrow"></i>Salas</Link></li>
                                            <li><Link to="/course-1"><i className="icon-Double-arrow"></i>Colabora</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget">
                                    <h5 className="widget-title">Contacto</h5>
                                    <div className="inner">
                                        <div className="widget-information">
                                            <ul className="information-list">
                                                <li><i className="icon-map-pin-line"></i> Club Rotario 3, Zona Federal, <br />22800 Ensenada, B.C.
                                                </li>
                                                <li><i className="icon-phone-fill"></i><a href="tel: + 52 (646) 152-1993">+ 52 (646) 152-1993</a></li>
                                                <li><i className="icon-mail-line-2"></i><a target="_blank" href="mailto:comunicacion@caracolbc.org">comunicacion@caracolbc.org</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="edu-footer-widget">
                                    <div className="logo">
                                        <Link to={process.env.PUBLIC_URL + "/"}>
                                            <img className="logo-light" src="/images/logo/logo-type.png" alt="Footer Logo" />
                                        </Link>
                                    </div>
                                    <div className="social-share-center">

                                        <div className="quote-icon quote-search">
                                            <button className="white-icon-menu search-trigger header-search" onClick={onSearchHandler}>
                                                <i className="ri-facebook-line"></i>
                                            </button>
                                        </div>
                                        <div className="quote-icon quote-search">
                                            <button className="white-icon-menu search-trigger header-search" onClick={onSearchHandler}>
                                                <i className="ri-twitter-line"></i>
                                            </button>
                                        </div>
                                        <div className="quote-icon quote-search">
                                            <button className="white-icon-menu search-trigger header-search" onClick={onSearchHandler}>
                                                <i className="ri-instagram-line"></i>
                                            </button>
                                        </div>
                                        <div className="quote-icon quote-search">
                                            <button className="white-icon-menu search-trigger header-search" onClick={onSearchHandler}>
                                                <i className="ri-youtube-line"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-21-01.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-35.png" alt="Shape Thumb" />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="copyright-area copyright-default">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    {<p>Caracol - <a href="#">Museo de Ciencias</a> Derechos reservados - 2024</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
            <ScrollTopButton />
        </>
    )
}

export default FooterOne;