import React from 'react';
import { Link } from 'react-router-dom';

const BannerVideo = () => {
    return (


        <div className="background-video ">
            <video playsInline="playsInline" autoPlay loop muted poster="videos/home-video-banner.jpg">
                <source src='videos/bg.webm' type="video/mp4" />
            </video>

            <div className="container eduvibe-animated-shape">

                <div className="row g-5 align-items-center">
                    <div className="col-lg-2 order-2 order-lg-1" />
                    <div className="col-lg-8 order-2 order-lg-1">
                        <div className="inner mt_md--10 mt_sm--10">
                            <div className="content text-center">
                                <span className="pre-title">Caracol Museo de Ciencias</span>
                                <h1 className="title">El Museo de <br/> Baja California</h1>
                                <p className="description">Somos un museo de y para la comunidad que promueve la divulgación de las maravillas naturales del lugar en el que vivimos.  Además, de informar sobre el quehacer científico que estudia nuestra hermosa península, el museo promueve la conservación y el cuidado del medio ambiente.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 order-2 order-lg-1" />
                </div>
            </div>

            <div className="container h-100">
                <div className="d-flex h-100 text-center align-items-center">
                    <div className="w-100 text-white">
                        <h1 className="display-3"></h1>
                        <p className="lead mb-0"></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerVideo;