import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import FooterTwo from '../../common/footer/FooterTwo';
import BannerDirectorio from '../../components/banner/BannerDirectorio';
import ContenidoDirectorio from '../innerpages/ContenidoDirectorio';


const Directorio = () => {
    return (
        <>
            <SEO title="Caracol Museo de Ciencias" />

            <HeaderTwo styles="header-caracol header-style-2" searchDisable />            
            <BannerDirectorio />
            <ContenidoDirectorio/>
            <FooterTwo />   
        </>
    )
}

export default Directorio;