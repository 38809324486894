import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useParams } from 'react-router-dom';
import { slugify } from '../../utils';
import SEO from '../../common/SEO';
import PostData from '../../data/blog/RecursosData.json';
import HeaderTwo from '../../common/header/HeaderTwo';
import FooterTwo from '../../common/footer/FooterTwo';
import Recurso from '../../components/post/Recurso';

const RecursoArchive = () => {
    const { slug } = useParams();
    const data = PostData.map(blog => {
        return {
            ...blog,
            categories: blog.categories.filter(catItem => slugify(catItem) === slug)
        }
    }).filter(blog => blog.categories.length > 0);

    const catTitle = data[0].categories[0];

    return (
        <>
            <SEO title="Caracol Museo de Ciencias" />
            <HeaderTwo styles="header-caracol header-style-2" searchDisable />

            <div className="edu-elements-area edu-section-gap bg-color-white">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-12">
                            <div className="row g-5">
                                {
                                    data.map((item) => (
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}
                                            className="col-lg-4 col-md-6 col-12"
                                            key={item.id}
                                        >
                                            <Recurso data={item} />
                                        </ScrollAnimation>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterTwo />

        </>
    )
}

export default RecursoArchive;