import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';


const BannerChicoPoliticaPrivacidad = () => {
    return (
        <div className="slider-area banner-style-chico-educacion bg-image">
            <div className="wrapper d-flex align-items-center home-four-banner-wrapper">
                <div className="container eduvibe-animated-shape">

                    <div className="row g-5 align-items-center">
                        <div className="col-lg-2 order-2 order-lg-1" />
                        <div className="col-lg-8 order-2 order-lg-1">
                            <div className="inner mt_md--10 mt_sm--10">
                                <div className="content text-center">
                                    <h1 className="title">Lee nuestra política de privacidad</h1>
                                    <div className="read-more-btn">
                                        <Link className="edu-btn" to={process.env.PUBLIC_URL + `/politicaprivacidad`}>+ VER<i className="icon-arrow-right-line-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 order-2 order-lg-1" />
                    </div>
                </div>

            </div>
        </div>



    )
}

export default BannerChicoPoliticaPrivacidad;



