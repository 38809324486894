import { Link } from 'react-router-dom';
import { Component } from "react";
import ReactGA from 'react-ga';

class FooterTwo extends Component {
    setGA = () => {
        ReactGA.initialize('UA-228692796-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    };
    componentDidMount() {
        this.setGA();
    }
    render() {
        const AbrirFacebook = () => {
            window.open("https://www.facebook.com/museocaracol")
        }
        const AbrirTwitter = () => {
            window.open("https://twitter.com/MuseoCaracol")
        }
        const AbrirInstagram = () => {
            window.open("https://www.instagram.com/museocaracol/")
        }
        const AbrirYoutube = () => {
            window.open("https://www.youtube.com/channel/UCAaBLwSMnaplsTDWlXOlhWw")
        }

        const AbrirTikTok = () => {
            window.open("https://www.tiktok.com/@museocaracol")
        }

        const AbrirAndroid = () => {
            window.open("https://play.google.com/store/apps/details?id=com.caracoldev.museocaracolapp")
        }
        
        return (
            <footer className="eduvibe-footer-two edu-footer-with-cta">
                <div className="edu-footer footer-style-default pb--85">
                    <div className="footer-top">
                        <div className="container eduvibe-animated-shape">
                            <div className="row g-5">
                                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                    <div className="edu-footer-widget">
                                        <div className="logo">
                                            <Link to={process.env.PUBLIC_URL + "/"}>
                                                <img className="logo-light" src="/images/logo/logo-type.png" alt="Footer Logo" />
                                            </Link>
                                        </div>
                                        <div className="social-share-center">

                                            <div className="quote-icon quote-search">
                                                <button className="white-icon-menu search-trigger header-search" onClick={AbrirFacebook}>
                                                    <i className="ri-facebook-line"></i>
                                                </button>
                                            </div>
                                            <div className="quote-icon quote-search">
                                                <button className="white-icon-menu search-trigger header-search" onClick={AbrirTwitter}>
                                                    <i className="ri-twitter-line"></i>
                                                </button>
                                            </div>
                                            <div className="quote-icon quote-search">
                                                <button className="white-icon-menu search-trigger header-search" onClick={AbrirInstagram}>
                                                    <i className="ri-instagram-line"></i>
                                                </button>
                                            </div>
                                            <div className="quote-icon quote-search">
                                                <button className="white-icon-menu search-trigger header-search" onClick={AbrirYoutube}>
                                                    <i className="ri-youtube-line"></i>
                                                </button>
                                            </div>
                                            <div className="quote-icon quote-search">
                                                <button className="white-icon-menu search-trigger header-search" onClick={AbrirTikTok}>
                                                    <i className="icon-tiktok"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="linkApps">
                                            <img  src="/images/footer/apps_android.png" alt="Footer Logo" onClick={AbrirAndroid}></img>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--70">
                                    <div className="edu-footer-widget explore-widget">
                                        <h5 className="widget-title">Explora</h5>
                                        <div className="inner">
                                            <ul className="footer-link link-hover">
                                                <li><Link to={process.env.PUBLIC_URL + `/conocenos`}><i className="icon-Double-arrow"></i>Conócenos</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + `/Visitanos`}><i className="icon-Double-arrow"></i>Visítanos</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + `/Educacion`}><i className="icon-Double-arrow"></i>Descubre</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + `/Salas`}><i className="icon-Double-arrow"></i>Salas</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-6 col-12  mt--70">
                                    <div className="edu-footer-widget quick-link-widget">
                                        <h5 className="widget-title">Enlaces útiles</h5>
                                        <div className="inner">
                                            <ul className="footer-link link-hover">
                                                <li><Link to={process.env.PUBLIC_URL + `/Contactanos`}><i className="icon-Double-arrow"></i>Contáctanos</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + `/Recursos`}><i className="icon-Double-arrow"></i>Recursos didácticos</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + `/Colabora`}><i className="icon-Double-arrow"></i>Donaciones</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--70">
                                    <div className="edu-footer-widget">
                                        <h5 className="widget-title">Contacto</h5>
                                        <div className="inner">
                                            <div className="widget-information">
                                                <ul className="information-list">
                                                    <li><i className="icon-map-pin-line"></i><a target="_blank" href="https://goo.gl/maps/cL592zbucrvBz6tf6">Club Rotario 3, Zona Federal, <br />22800. Ensenada, B.C.</a> </li>
                                                    <li><i className="icon-phone-fill"></i><a href="tel: + 52 (646) 152-1993">+ 52 (646) 152-1993</a></li>
                                                    <li><i className="icon-mail-line-2"></i><a target="_blank" href="mailto:comunicacion@caracolbc.org">contacto@caracolbc.org</a></li>
                                                    <li><i className="icon-time-line"></i>Jueves a Domingo de 10:00 a.m.-5:00 p.m.</li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
                                <div className="animate-image shape-image-1">
                                    <img src="/images/shapes/shape-21-01.png" alt="Shape Thumb" />
                                </div>
                                <div className="animate-image shape-image-2">
                                    <img src="/images/shapes/shape-35.png" alt="Shape Thumb" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area copyright-default">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    {<p>Caracol - <a href="#">Museo de Ciencias V1.02</a> Derechos reservados - 2021<Link to={process.env.PUBLIC_URL + `/boletos`}>-</Link></p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        )
    }
}
export default FooterTwo;