import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerCovid from '../../components/banner/BannerCovid';
import FooterTwo from '../../common/footer/FooterTwo';
import CovidRules from '../../pages/innerpages/CovidRules';


const Covid = () => {
    return (
        <>
            <SEO title="Caracol Museo de Ciencias" />

            <HeaderTwo styles="header-caracol header-style-2" searchDisable />            
            <BannerCovid />
            <CovidRules />
            <FooterTwo />   
        </>
    )
}

export default Covid;