import React from 'react';
import { NavHashLink } from 'react-router-hash-link';


const Nav = () => {

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = 0; 
        setTimeout(function () {
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
        },2);    }

    return (
        <ul className="mainmenu">
            <li >
                <NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/`}>INICIO</NavHashLink>
    
            </li>
            <li className="has-droupdown">
                <NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/conocenos`}>CONÓCENOS</NavHashLink>
                <ul className="submenu">
                    <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/conocenos#section1`}>Historia</NavHashLink></li>
                    <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/conocenos#section2`}>Misión/Visión</NavHashLink></li>
                    <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/conocenos#section3`}>Línea del tiempo</NavHashLink></li>
                    <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/Directorio`}>Directorio</NavHashLink></li>
                    <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/Transparencia`}>Transparencia</NavHashLink></li>
                    <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/politicaprivacidad`}>Política de privacidad</NavHashLink></li>
                    
                </ul>
            </li>

            <li className="has-droupdown">
                <NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/Visitanos`}>VISÍTANOS</NavHashLink>
                <ul className="submenu">
                <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/Visitanos#section1`}>Boletos</NavHashLink></li>
                <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/Visitanos#content-box`}>Ubicación</NavHashLink></li>
                <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/Visitanos#section3`}>Eventos</NavHashLink></li>
                <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/salas`}>Salas/Exhibiciones</NavHashLink></li>
                <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/salas#section1`}>Sala del Cielo</NavHashLink></li>
                <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/salas#sectionplanetario`}>Planetario</NavHashLink></li>
                <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/salas#section2`}>Sala de la Tierra</NavHashLink></li>
                <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/salas#section3`}>Sala del Mar</NavHashLink></li>
                <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/salas#section4`}>Sala Temporal</NavHashLink></li>
                </ul>
            </li>
   
            {/*<li className="has-droupdown">
                <NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/salas`}>SALAS</NavHashLink>
                <ul className="submenu">
                    <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/salas#section1`}>Sala del Cielo</NavHashLink></li>
                    <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/salas#section2`}>Sala de la Tierra</NavHashLink></li>
                    <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/salas#section3`}>Sala del Mar</NavHashLink></li>
                </ul>
            </li>
 */}
            <li className="has-droupdown">
            <NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/Educacion`}>DESCUBRE</NavHashLink>
                <ul className="submenu">
                    <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/Educacion`}>Artículos</NavHashLink></li>
                    <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/Recursos`}>Recursos</NavHashLink></li>
                </ul>
            </li>

            <li className="has-droupdown">
            <NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/Colabora`}>COLABORA</NavHashLink>
                <ul className="submenu">
                <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/Colabora#section1`}>Membresías</NavHashLink></li>
                <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/Colabora#section2`}>Donaciones</NavHashLink></li>
                <li><NavHashLink scroll={el => scrollWithOffset(el)}  to={process.env.PUBLIC_URL + `/Colabora#section3`}>Dejando huella</NavHashLink></li>
                </ul>
            </li>
        </ul>
    )
}
export default Nav;
