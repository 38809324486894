import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

const EntradaBoleto = ({ data, classes, bgWhite }) => {
    var date = new Date(data.metadata.fecha);
    const fecha = date.getDay() + "/" + date.getMonth() + "/" + date.getFullYear();
    const hora = (date.getUTCHours() < 10 ? "0" : "") + date.getUTCHours() + ":" + (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    return (
        <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
            className={classes || 'col-lg-12'}
        >
            <div className={`edu-event event-list radius-small bg-boletos`}>
                <div className="inner">
                    <div className="content">
                        <ul className="event-meta">
                            <li className="has-dark-color"><i className="ri-user-line"></i>{data.metadata.nombre}</li>
                            <li className="has-dark-color"><i className="icon-checkbox-circle-line"></i>{data.metadata.id_de_transaccion}</li>
                            <li className="has-dark-color"><i className="icon-time-line"></i>{hora}</li>
                            <li className="has-dark-color"><i className="icon-calendar-2-line"></i>{fecha}</li>
                            <li className="has-dark-color"><i className="ri-ticket-line"></i>{data.metadata.cantidad_de_boletos}</li>
                            <li className="has-dark-color"><i className="ri-money-cny-box-line"></i>{data.metadata.monto}</li>
                            <li className="has-dark-color"><i className="ri-contacts-book-line"></i>{data.metadata.email}</li>
                        </ul>

                    </div>
                    <div className="content">
                        <ul className="event-meta">
                            <li className="has-dark-color"><i className="ri-database-line"></i>{data.metadata.detalle_de_compra}</li>
                        </ul>
                    </div>

                </div>
            </div>
        </ScrollAnimation >
    )
}

export default EntradaBoleto;