import React from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils';

const PostOne = ( { data, classes, bgWhite } ) => {
   
    
    var date = new Date(data.created); 
    const fecha=date.getDay()+"/"+date.getMonth()+"/"+date.getFullYear();

    var categoria="";
    var categoriaSlug="";
    if(data.metadata!=undefined){
        categoria=data.metadata.categories[0].title;
        categoriaSlug=data.metadata.categories[0].slug;
    }
    return (
        <div className={`edu-blog blog-type-2 radius-small ${classes ? classes : '' } ${bgWhite === 'enable' ? 'bg-color-white' : '' }`}>
            <div className="inner">
                <div className="thumbnail">
                    <Link to={process.env.PUBLIC_URL + `/blog-details/${data.slug}`}>
                        <img src={data.metadata.portada.imgix_url} alt="Blog Thumb" />
                    </Link>
                </div>
                <div className="content">
                    <div className="status-group">
                        <a className="eduvibe-status status-05 has-dark-color" to={process.env.PUBLIC_URL + `/category/${ categoriaSlug }`}>
                            <i className="icon-price-tag-3-line"></i> {categoria}
                        </a>
                    </div>
                    <h5 className="title has-dark-color">
                        <Link to={process.env.PUBLIC_URL + `/blog-details/${data.slug}`}>{data.title}</Link>
                    </h5>
                    <div className="blog-card-bottom">
                        <ul className="blog-meta">
                            <li className="has-dark-color"><i className="icon-calendar-2-line"></i>{ fecha }</li>
                        </ul>
                        <div className="read-more-btn">
                            <Link className="btn-transparent" to={process.env.PUBLIC_URL + `/blog-details/${data.slug}`}>Leer más<i className="icon-arrow-right-line-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostOne;