import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import ScrollAnimation from 'react-animate-on-scroll';


const BannerTierra = () => {

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -70; 
        setTimeout(function () {
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
        },2);    }

    return (
        <div className="slider-area banner-style-tierra bg-image">
            <div className="wrapper d-flex align-items-center home-four-banner-wrapper">


                <div className="container eduvibe-animated-shape">

                    <div className="row g-5 align-items-center">
                        <div className="col-lg-2 order-2 order-lg-1" />
                        <div className="col-lg-8 order-2 order-lg-1">
                            <div className="inner mt_md--10 mt_sm--10">
                                <div className="content text-center">
                                    <span className="pre-title">Sala de la tierra</span>
                                    <h1 className="title">Explora nuestro paraíso terrestre</h1>
                                    <p className="description">Descubre el asombroso lugar en el que vivimos, y la increíble biodiversidad que habita nuestra península.</p>
                                    <div className="read-more-btn">
                                    <NavHashLink scroll={el => scrollWithOffset(el)} className="edu-btn" to={process.env.PUBLIC_URL + '/salas#section2'}>VER MÁS<i className="icon-arrow-right-line-right"></i></NavHashLink>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 order-2 order-lg-1" />
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image-sala shape-image-Borrego">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                        >
                            <img src="/images/imgsHome/Borrego.webp" alt="Shape Thumb" />
                        </ScrollAnimation>
                        <div className="shape-nombre">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                            >
                                <img src="/images/imgsHome/Borrego-nombre.webp" alt="Shape Thumb" />
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="shape-image-sala shape-image-Cascabel">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                        >
                            <img src="/images/imgsHome/Cascabel.webp" alt="Shape Thumb" />
                        </ScrollAnimation>
                        <div className="shape-nombre">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                            >
                                <img src="/images/imgsHome/Cascabel-nombre.webp" alt="Shape Thumb" />
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="shape-image-sala shape-image-Cardon">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                        >
                            <img src="/images/imgsHome/Cardon.webp" alt="Shape Thumb" />
                        </ScrollAnimation>
                        <div className="shape-nombre">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                            >
                                <img src="/images/imgsHome/Cardon-nombre.webp" alt="Shape Thumb" />
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="shape-image-sala shape-image-Puma">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                        >
                            <img src="/images/imgsHome/Puma.webp" alt="Shape Thumb" />
                        </ScrollAnimation>
                        <div className="shape-nombre">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                            >
                                <img src="/images/imgsHome/Puma-nombre.webp" alt="Shape Thumb" />
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="shape-image-sala shape-image-Lagartija">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                             
                        >
                            <img src="/images/imgsHome/Lagartija.webp" alt="Shape Thumb" />
                        </ScrollAnimation>
                        <div className="shape-nombre">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                            >
                                <img src="/images/imgsHome/Lagartija-nombre.webp" alt="Shape Thumb" />
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="shape-image-sala shape-image-Condor">
                        <ScrollAnimation
                            animateIn="fadeInUp"
                        >
                            <img src="/images/imgsHome/Condor.webp" alt="Shape Thumb" />
                        </ScrollAnimation>
                        <div className="shape-nombre">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                            >
                                <img src="/images/imgsHome/Condor-nombre.webp" alt="Shape Thumb" />
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerTierra;
