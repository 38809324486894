import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import Home from './pages/homepages/Home';
import Home1 from './pages/homepages/HomeOne';
import Covid from './pages/homepages/Covid';
import Transparencia from './pages/homepages/Transparencia';
import PoliticaPrivacidad from './pages/homepages/PoliticaPrivacidad';
import Conocenos from './pages/homepages/Conocenos';
import Salas from './pages/homepages/Salas';
import BlogDetailsOne from './pages/detailspages/BlogDetailsOne';
import Colabora from './pages/homepages/Colabora';
import Visitanos from './pages/homepages/Visitanos';
import Directorio from './pages/homepages/Directorio';
import Recursos from './pages/blog/Recursos';
import Educacion from './pages/blog/Educacion';
import Boletos from './pages/blog/Boletos';
import CategoryArchive from './pages/archive/CategoryArchive';
import RecursoArchive from './pages/archive/RecursoArchive';
import ContactUs from './pages/innerpages/ContactUs';
import QRPage from './pages/homepages/QRPage';
import HttpsRedirect from 'react-https-redirect';
import Test from './pages/course/CourseFilterOne';
// Import Css Here 
import './assets/scss/style.scss';

function App() {
    return (
            <Router>
                <ScrollToTop>
                    <Routes>
                        <Route exact path='/' element={<Home />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/covid'}`} element={<Covid />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/test'}`} element={<Test />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/Home1'}`} element={<Home1 />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/Contactanos'}`} element={<ContactUs />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/directorio'}`} element={<Directorio />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/recursos'}`} element={<Recursos />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/transparencia'}`} element={<Transparencia />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/politicaprivacidad'}`} element={<PoliticaPrivacidad />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/conocenos'}`} element={<Conocenos />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/qrpage'}`} element={<QRPage />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/colabora'}`} element={<Colabora />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/visitanos'}`} element={<Visitanos />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/educacion'}`} element={<Educacion />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/boletos'}`} element={<Boletos />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/blog-details/:id'}`} element={<BlogDetailsOne />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/salas'}`} element={<Salas />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/category/:slug'}`} element={<CategoryArchive />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/category-recurso/:slug'}`} element={<RecursoArchive />} />
                        <Route path='*' element={<Home />} />
                    </Routes>
                </ScrollToTop>
            </Router>
    );
}

export default App;
