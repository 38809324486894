import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerTransparencia from '../../components/banner/BannerTransparencia';
import FooterTwo from '../../common/footer/FooterTwo';
import ContenidoTransparencia from '../../components/ContenidoTransparencia';
import ListaDescargables from '../innerpages/ListaDescargables';
import BannerChicoPoliticaPrivacidad from '../../components/banner/BannerChicoPoliticaPrivacidad';


const Transparencia = () => {
    return (
        <>
            <SEO title="Caracol Museo de Ciencias" />

            <HeaderTwo styles="header-caracol header-style-2" searchDisable />            
            <BannerTransparencia />
            <ContenidoTransparencia />
            <ListaDescargables/>
            <BannerChicoPoliticaPrivacidad />
            <FooterTwo />   
        </>
    )
}

export default Transparencia;