import React, { useState, useContext } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import SectionTitle from '../components/sectionTitle/SectionTitle';

const pricingPlan = {
    rightColumn: [
        {
            id: 1,
            title: ' NACIÓ EL CARACOL?',
            body: 'En 1988, Museo Caracol nació en el corazón y en la mente de un grupo de educadores y científicos que adoptaron a Ensenada como residencia y tuvieron claro el valor que un Museo de Ciencias aportaría al desarrollo cultural de nuestra comunidad.            La Profesora Estela Parrilla, líder del proyecto, y el M.C. Manuel Álvarez, supieron cómo contagiar la pasión de su sueño a otras personas de la comunidad científica, del sector empresarial y otros campos. En 1988, el grupo funda Tecciztli de Baja California, S.C. 10 años después, para ampliar las posibilidades del proyecto, éste evoluciona a Museo Caracol, Centro Científico y Cultural, A.C. De 1989 al 2002 se dio vida a un museo único en la región. Con recursos propios y posteriormente con el apoyo del XIII Ayuntamiento, ofreció exposiciones originales y experiencias educativas que disfrutaron miles de escolares y público general de todo el Estado.'
        },
        {
            id: 2,
            title: 'CONTINUÓ EL CAMINO DEL CARACOL',
            body: 'En 1988, Museo Caracol nació en el corazón y en la mente de un grupo de educadores y científicos que adoptaron a Ensenada como residencia y tuvieron claro el valor que un Museo de Ciencias aportaría al desarrollo cultural de nuestra comunidad.            La Profesora Estela Parrilla, líder del proyecto, y el M.C. Manuel Álvarez, supieron cómo contagiar la pasión de su sueño a otras personas de la comunidad científica, del sector empresarial y otros campos. En 1988, el grupo funda Tecciztli de Baja California, S.C. 10 años después, para ampliar las posibilidades del proyecto, éste evoluciona a Museo Caracol, Centro Científico y Cultural, A.C. De 1989 al 2002 se dio vida a un museo único en la región. Con recursos propios y posteriormente con el apoyo del XIII Ayuntamiento, ofreció exposiciones originales y experiencias educativas que disfrutaron miles de escolares y público general de todo el Estado.'
        },
        {
            id: 3,
            title: 'EL CARACOL HOY EN DIA',
            body: 'En 1988, Museo Caracol nació en el corazón y en la mente de un grupo de educadores y científicos que adoptaron a Ensenada como residencia y tuvieron claro el valor que un Museo de Ciencias aportaría al desarrollo cultural de nuestra comunidad.            La Profesora Estela Parrilla, líder del proyecto, y el M.C. Manuel Álvarez, supieron cómo contagiar la pasión de su sueño a otras personas de la comunidad científica, del sector empresarial y otros campos. En 1988, el grupo funda Tecciztli de Baja California, S.C. 10 años después, para ampliar las posibilidades del proyecto, éste evoluciona a Museo Caracol, Centro Científico y Cultural, A.C. De 1989 al 2002 se dio vida a un museo único en la región. Con recursos propios y posteriormente con el apoyo del XIII Ayuntamiento, ofreció exposiciones originales y experiencias educativas que disfrutaron miles de escolares y público general de todo el Estado.'
        }
    ]
}

const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey);
    const isCurrentEventKey = activeEventKey === eventKey;
    return <button type="button" onClick={decoratedOnClick} aria-expanded={isCurrentEventKey ? true : false} className="edu-accordion-button">{children}</button>
}

const ContenidoConocenos = ({ wrapperclassName }) => {

    const [selectedItem, setSelectedItem] = useState('online-education');
    const [activeId1, setActiveId1] = useState('0');
    const [activeId2, setActiveId2] = useState('0');
    const [activeId3, setActiveId3] = useState('0');
    const [activeId4, setActiveId4] = useState('0');
    const [activeId5, setActiveId5] = useState('0');
    const [activeId6, setActiveId6] = useState('0');

    function toggleActive1(id) {
        if (activeId1 === id) {
            setActiveId1(null);
        } else {
            setActiveId1(id);
        }
    }
    function toggleActive2(id) {
        if (activeId2 === id) {
            setActiveId2(null);
        } else {
            setActiveId2(id);
        }
    }
    function toggleActive3(id) {
        if (activeId3 === id) {
            setActiveId3(null);
        } else {
            setActiveId3(id);
        }
    }
    function toggleActive4(id) {
        if (activeId4 === id) {
            setActiveId4(null);
        } else {
            setActiveId4(id);
        }
    }
    function toggleActive5(id) {
        if (activeId5 === id) {
            setActiveId5(null);
        } else {
            setActiveId5(id);
        }
    }
    function toggleActive6(id) {
        if (activeId6 === id) {
            setActiveId6(null);
        } else {
            setActiveId6(id);
        }
    }

    return (
        <div id="section1" className={`edu-accordion-area accordion-shape-1 bg-color-white `}>
            <div className="container   pb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="has-cara-blue is-centered pad-top-30">HISTORIA</h3>
                    </div>
                </div>
                <div className="row g-5 align-items-center">
                    <div className="col-lg-12">
                        <ScrollAnimation
                            animateIn="fadeIn"
                            animateOut="fadeInOut"
                            className="accordion-style-1"
                            animateOnce={true}
                        >
                            <div className="row g-5 mt--10">
                                <div className="col-xl-10 offset-xl-1">
                                    <div className="animated fadeInUp" >
                                        <div className=" edu-accordion-02 variation-2 ">

                                            <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                                <Accordion.Item eventKey="0" key="0" onClick={() => toggleActive6("0")} bsPrefix={`edu-accordion-item ${activeId6 === "0" ? 'active-item' : ''}`}>
                                                    <div className="edu-accordion-header">
                                                        <CustomToggle eventKey="0">¿CÓMO NACIÓ CARACOL? </CustomToggle>
                                                    </div>
                                                    <Accordion.Body bsPrefix="edu-accordion-body has-dark-color">
                                                        <div className="row">

                                                            <div className="col-lg-6">
                                                                En 1988, Caracol nació en el corazón y en la mente de un grupo de educadores y científicos que adoptaron a Ensenada como residencia y tuvieron claro el valor que un Museo de Ciencias aportaría al desarrollo cultural de nuestra comunidad.
                                                                <br /><br />
                                                                Los líderes del proyecto, la Profesora <strong>Estela Parrilla y el M.C. Manuel Álvarez</strong>, supieron contagiar la pasión de su sueño a otras personas de la comunidad científica, del sector empresarial y otros campos. En 1988, el grupo funda Tecciztli de Baja California, S.C. y 10 años después, este proyecto evoluciona a Caracol, Centro Científico y Cultural, A.C.
                                                                <br /><br />
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="container align-middle">

                                                                    <img className="one " src={`${process.env.PUBLIC_URL}./images/conocenos/barca.jpg`} alt="Footer Logo" />

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">

                                                            <div className="col-lg-12">
                                                                De 1989 al 2002 se dio vida a un museo único en la región que ofreció exposiciones originales, incluso dentro de un arca ubicada en sus jardines, y experiencias educativas que disfrutaron miles de escolares y público general. Al inicio funcionó con recursos propios y posteriormente con el apoyo del XIII Ayuntamiento.


                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="1" key="1" onClick={() => toggleActive6("1")} bsPrefix={`edu-accordion-item ${activeId6 === "1" ? 'active-item' : ''}`}>
                                                    <div className="edu-accordion-header">
                                                        <CustomToggle eventKey="1">CONTINUÓ EL CAMINO DE CARACOL</CustomToggle>
                                                    </div>
                                                    <Accordion.Body bsPrefix="edu-accordion-body has-dark-color">
                                                        El museo siempre ha promovido la cultura científica y la educación ambiental, detonando el interés sobre sitios como La Lagunita de El Ciprés. <strong>
                                                            Fue el primer organismo promotor de los avistamientos de ballenas grises en la Bahía de Ensenada.</strong>
                                                        En el año 2002, este emblemático museo cierra sus puertas y Caracol centra sus esfuerzos en la búsqueda de recursos para su consolidación. La empresa Museotec desarrolla los proyectos arquitectónico y museográfico y, en el año 2006, inicia su construcción. La obra realizada hasta hoy, 9,800 mts², se construyó en seis etapas en una superficie de 6,255 mts².
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="2" key="2" onClick={() => toggleActive6("2")} bsPrefix={`edu-accordion-item ${activeId6 === "2" ? 'active-item' : ''}`}>
                                                    <div className="edu-accordion-header">
                                                        <CustomToggle eventKey="2">EL CARACOL HOY EN DIA</CustomToggle>
                                                    </div>
                                                    <Accordion.Body bsPrefix="edu-accordion-body has-dark-color">
                                                        Valiosas personas de la comunidad han aportado tiempo, experiencia y recursos a este proyecto: la Administración Portuaria Integral facilitó la adquisición del mejor lugar para construirlo; los gobiernos federales y estatales han aportado el mayor porcentaje de recursos invertidos; los gobiernos municipales, instituciones de Educación e Investigación Superior, el sector empresarial y organizaciones civiles, han brindado apoyo, asesoría y experiencia.
                                                        <br /><br />

                                                        Nuestros consejos directivos y patronatos supieron persistir y mantener el sueño vivo. Desde octubre de 2013, aún sin estar inaugurado, Caracol ofreció exposiciones temporales, talleres, conferencias, visitas a escuelas y múltiples ediciones del Día de la Tierra, recibiendo a más de 12,000 usuarios en dos años. El 7 de diciembre de 2015 abre oficialmente sus puertas presentando una primera etapa.
                                                        <br /><br />

                                                        Actualmente, Caracol cuenta con la Sala de la Tierra, la primera etapa de la Sala del Cielo, un Planetario y el Proyecto Sala del Mar ¡Sumérgete y descubre!, una muestra de lo que será la Sala del Mar y Acuario.
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ScrollAnimation>
                    </div>


                </div>
            </div>
            <div id="content-box" className="container-fluid px-lg-5 py-5 text-section pb-3">
                <div className="row" id="section2">
                    <div className="col-lg-6">
                        <div className="container">
                            <h5 className="has-dark-color is-heavy">MISIÓN</h5>
                            <p className="has-dark-color">
                                Propiciar en nuestros niños y jóvenes la curiosidad y el placer
                                de descubrir, a través de actividades recreativas, experimentos y observaciones. Fomentar valores y
                                habilidades que faciliten el desarrollo de la creatividad, el respeto al medio ambiente y el sentido
                                positivo de participación y pertenencia a nuestra comunidad.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="container">
                            <h5 className="has-dark-color is-heavy">VISIÓN</h5>
                            <p className="has-dark-color">
                                Ser un museo líder en divulgación, promoción, difusión de la ciencia y tecnología, a través de
                                instalaciones, exhibiciones, talleres, actividades, gestiones de contenido educativo y cultural, que
                                despierten en los visitantes y usuarios del museo un interés activo por la ciencia. Además de
                                promover
                                el conocimiento, respeto y cuidado por el medio ambiente en general y en particular de la península
                                de
                                Baja California.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>




    )
}

export default ContenidoConocenos;


