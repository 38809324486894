import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';


const BannerCovid = () => {
    return (
        <div className="slider-area banner-style-covid bg-image">
            <div className="wrapper d-flex align-items-center home-four-banner-wrapper">


                <div className="container eduvibe-animated-shape">

                    <div className="row g-5 align-items-center">
                        <div className="col-lg-2 order-2 order-lg-1" />
                        <div className="col-lg-8 order-2 order-lg-1">
                            <div className="inner mt_md--10 mt_sm--10">
                                <div className="content text-center">
                                    <span className="pre-title"></span>
                                    <h1 className="title">CONOCE NUESTRO PROTOCOLO DE SEGURIDAD</h1>
                                    <p className="description">
                                    En Caracol Museo de Ciencias tomamos muy en serio las medidas sanitarias actuales</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    )
}

export default BannerCovid;