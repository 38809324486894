import React from 'react';

const Recurso = ({ data, classes, bgWhite }) => {

    var date = new Date(data.created);
    const fecha = date.getDay() + "/" + date.getMonth() + "/" + date.getFullYear();

    var categoria = "";
    var categoriaSlug = "";
    if (data.metadata != undefined) {
        categoria = data.metadata.categoria[0].title;
        categoriaSlug = data.metadata.categoria[0].slug;
    }

    return (
        <div className={`edu-blog blog-type-2 radius-small ${classes ? classes : ''} ${bgWhite === 'enable' ? 'bg-color-white' : ''}`}>
            <div className="inner">
                <div className="thumbnail">
                    <img src={data.metadata.portada.imgix_url} alt="Blog Thumb" />
                </div>
                <div className="content">
                    <div className="status-group">

                        <a className="eduvibe-status status-05 has-dark-color" to={process.env.PUBLIC_URL + `/category-recurso/${categoriaSlug}`}>
                            <i className="icon-price-tag-3-line"></i> {categoria}
                        </a>
                    </div>
                    <ul className="blog-meta">
                        <li className="has-dark-color"><i className="icon-calendar-2-line"></i>{fecha}</li>
                    </ul>
                    <h5 className="title has-dark-color">
                        {data.title}
                    </h5>
                    <p className='has-dark-color' dangerouslySetInnerHTML={{ __html: data.metadata.texto }}></p>

                    <div className="blog-card-bottom">


                        <div className="read-more-btn">
                            {/*to={process.env.PUBLIC_URL + `/blog-details/${data.id}` */}
                            <a className="btn-transparent" href={data.metadata.recurso.url}
                                download target="_blank" >
                                    Bajar recurso<i className="icon-arrow-down-s-fill"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Recurso;