import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import FooterTwo from '../../common/footer/FooterTwo';
import BannerVisitanos from '../../components/banner/BannerVisitanos';
import ContenidoVisitanos from '../innerpages/ContenidoVisitanos';
import LinkBannerVisitanos from '../innerpages/LinkBannerVisitanos';
import GaleriaEventos from '../../components/GaleriaEventos';
import BannerChicoSalas from '../../components/banner/BannerChicoSalas';


const Visitanos = () => {
    return (
        <>
            <SEO title="Caracol Museo de Ciencias" />

            <HeaderTwo styles="header-caracol header-style-2" searchDisable />            
            <BannerVisitanos />
            <ContenidoVisitanos />
            <BannerChicoSalas /> 
            <GaleriaEventos />
            <LinkBannerVisitanos/>
            <FooterTwo />   
        </>
    )
}

export default Visitanos;