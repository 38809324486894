import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerColabora from '../../components/banner/BannerColabora';
import FooterTwo from '../../common/footer/FooterTwo';
import ContenidoColabora from '../innerpages/ContenidoColabora';
import GaleriaDonadores from '../../components/GaleriaDonadores';


const Colabora = () => {
    return (
        <>
            <SEO title="Caracol Museo de Ciencias" />

            <HeaderTwo styles="header-caracol header-style-2" searchDisable />            
            <BannerColabora />
            <ContenidoColabora />
            {/*<GaleriaDonadores/>*/}
            <FooterTwo />   
        </>
    )
}

export default Colabora;